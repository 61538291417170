import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confidentiality-agreement',
  templateUrl: './confidentiality-agreement.component.html',
  styleUrls: ['./confidentiality-agreement.component.scss'],
})
export class ConfidentialityAgreementComponent implements OnInit {
  @Input() contentCA: string;
  contentCAHTML: SafeHtml;

  constructor(private readonly modalController: ModalController, private readonly domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.contentCAHTML = this.domSanitizer.bypassSecurityTrustHtml(this.contentCA);
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

}
