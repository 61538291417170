import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Activity } from '../models/activity';
import { UserRepository } from '../repositories/user.repository';

@Injectable()
export class FavoriteActivitiesResolver implements Resolve<Array<Activity>> {
  constructor(
    private readonly router: Router,
    private readonly toastController: ToastController,
    private readonly userRepository: UserRepository
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Array<Activity>> {

    return this.userRepository.getFavoriteActivities()
      .pipe(
        catchError(() => this.handleError())
      );
  }

  private handleError(): Observable<Array<Activity>> {
    this.toastController.create({
      message: 'Impossible de récupérer les catégories d\'activités',
      duration: 2000
    })
      .then(t => t.present());
    this.router.navigate(['..']);

    return of();
  }
}
