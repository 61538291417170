import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var CategoriesRepository = /** @class */ (function (_super) {
    tslib_1.__extends(CategoriesRepository, _super);
    function CategoriesRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    CategoriesRepository.prototype.getModelName = function () {
        return 'categories';
    };
    CategoriesRepository.prototype.getInLibraryCategories = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'in-library'].join('/'));
    };
    CategoriesRepository.prototype.findByCode = function (code) {
        return this.http.get([this.baseUrl, this.getModelName(), 'by-code', code].join('/'));
    };
    CategoriesRepository.prototype.getActivityCategoryActivities = function (code) {
        return this.http.get([this.baseUrl, this.getModelName(), code, 'activities'].join('/'));
    };
    return CategoriesRepository;
}(AbstractRepository));
export { CategoriesRepository };
