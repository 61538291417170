import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { UserRepository } from '../../../repositories/user.repository';
export var repeatPasswordValidator = function (group) {
    var password = group.get('plainPassword').value;
    var passwordConfirmation = group.get('confirmPassword').value;
    return password === passwordConfirmation ? undefined : { passwordsNotEqual: true };
};
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(formBuilder, route, userRepository, toastController, alertController, router) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.userRepository = userRepository;
        this.toastController = toastController;
        this.alertController = alertController;
        this.router = router;
        this.submitted = false;
        this.error = false;
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        this.token = this.route.snapshot.queryParamMap.get('token');
        if (!this.token) {
            this.toastController.create({
                message: 'Aucun jeton : veuillez cliquer sur le lien présent dans le mail.',
                duration: 5000
            })
                .then(function (t) { return t.present(); });
            this.router.navigate(['login']);
        }
        this.resetPasswordForm = this.formBuilder.group({
            plainPassword: ['', [Validators.required, Validators.minLength(4)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: repeatPasswordValidator
        });
    };
    ResetPasswordComponent.prototype.resetPassword = function () {
        var _this = this;
        if (this.resetPasswordForm.invalid || this.submitted) {
            return;
        }
        this.submitted = true;
        this.userRepository
            .resetPassword(this.token, this.resetPasswordForm.get('plainPassword').value)
            .pipe(catchError(function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.error = true;
                        return [4 /*yield*/, this.alertController.create({
                                header: 'Erreur dans la réinitialisation de votre mot de passe',
                                message: 'Le lien utilisé pour changer votre votre mot de passe est invalide ou a expiré. Veuillez renouveler votre demande.',
                                buttons: [
                                    {
                                        text: 'J\'ai compris',
                                        handler: function () {
                                            _this.router.navigate(['request-reset-password']);
                                        }
                                    }
                                ]
                            })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, throwError(error)];
                }
            });
        }); }), finalize(function () {
            _this.submitted = false;
        }))
            .subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: 'Réinitialisation du mot de passe',
                            message: 'Votre mot de passe a bien été changé ! Vous pouvez désormais vous reconnecter.',
                            buttons: [
                                {
                                    text: 'J\'ai compris',
                                    handler: function () {
                                        _this.router.navigate(['login']);
                                    }
                                }
                            ]
                        })];
                    case 1:
                        alert = _a.sent();
                        if (!!this.error) return [3 /*break*/, 3];
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
