<ion-content scrollY="true">
  <div class="reset-password-wrapper" fxLayoutAlign="center center">
    <div fxLayoutAlign.gt-xs="center center" fxFlexFill>
      <ion-card class="reset-password">
      <ion-toolbar color="primary">
          <ion-buttons slot="start">
              <ion-button
                  [routerLink]="['/login']"
                  type="button"
              >
                  <ion-icon name="arrow-back"></ion-icon>
              </ion-button>
          </ion-buttons>
          <ion-title>Réinitialiser mon mot de passe (2/2)</ion-title>
      </ion-toolbar>
      <ion-card-content class="reset-password-content">
        <img class="reset-password-icon" src="assets/images/logo.png" alt="myCharlotte"/>
        <form fxLayout="column" fxLayoutGap="10px" [formGroup]="resetPasswordForm" (submit)="resetPassword()" (keyup.enter)="resetPassword()" novalidate>
          <ion-text>
            Veuillez saisir le nouveau mot de passe :
          </ion-text>

          <div>
            <ion-item>
              <ion-label position="stacked">Mot de passe <ion-text color="danger">*</ion-text></ion-label>
              <ion-input
                  type="password"
                  required
                  autocorrect="none"
                  autocapitalize="none"
                  formControlName="plainPassword"
                  placeholder="Mot de passe"
                  clearInput
              ></ion-input>
            </ion-item>
            <app-form-error
                [field]="resetPasswordForm.get('plainPassword')"
                [messages]="{
                'required': 'Veuillez confirmer le mot de passe',
                'minlength': 'Veuillez saisir 4 caractères au minimum'
              }"
            ></app-form-error>
          </div>

          <div>
            <ion-item>
              <ion-label position="stacked">Confirmez votre mot de passe <ion-text color="danger">*</ion-text></ion-label>
              <ion-input
                  type="password"
                  required
                  autocorrect="none"
                  autocapitalize="none"
                  formControlName="confirmPassword"
                  placeholder="Confirmez votre mot de passe"
                  clearInput
              ></ion-input>
            </ion-item>
            <app-form-error
                [field]="resetPasswordForm.get('confirmPassword')"
                [messages]="{'required': 'Veuillez saisir la confirmation'}"
            ></app-form-error>
            <ion-text color="danger">
              <span *ngIf="resetPasswordForm.hasError('passwordsNotEqual') && resetPasswordForm.get('confirmPassword').touched">Les mots de passe doivent être identiques</span>
            </ion-text>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" class="reset-password-button">
            <ion-button
              color="primary"
              type="submit"
              [disabled]="resetPasswordForm.invalid || submitted"
            >
              Modifier mon mot de passe
            </ion-button>
          </div>
        </form>
      </ion-card-content>
      </ion-card>
    </div>
  </div>
</ion-content>
