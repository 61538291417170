import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { UserRepository } from '../../../repositories/user.repository';

export const repeatPasswordValidator = (group: FormGroup) => {
  const password = group.get('plainPassword').value;
  const passwordConfirmation = group.get('confirmPassword').value;

  return password === passwordConfirmation ? undefined : { passwordsNotEqual: true };
};

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted = false;
  token: string;
  error = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly userRepository: UserRepository,
    private readonly toastController: ToastController,
    private readonly alertController: AlertController,
    private readonly router: Router) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (!this.token) {
      this.toastController.create({
        message: 'Aucun jeton : veuillez cliquer sur le lien présent dans le mail.',
        duration: 5000
      })
        .then(t => t.present());
      this.router.navigate(['login']);
    }

    this.resetPasswordForm = this.formBuilder.group({
      plainPassword: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: repeatPasswordValidator
    });
  }

  resetPassword(): void {
    if (this.resetPasswordForm.invalid || this.submitted) {
      return;
    }

    this.submitted = true;
    this.userRepository
      .resetPassword(this.token, this.resetPasswordForm.get('plainPassword').value)
      .pipe(
        catchError(async error => {
          this.error = true;
          const alert = await this.alertController.create({
            header: 'Erreur dans la réinitialisation de votre mot de passe',
            message: 'Le lien utilisé pour changer votre votre mot de passe est invalide ou a expiré. Veuillez renouveler votre demande.',
            buttons: [
              {
                text: 'J\'ai compris',
                handler: () => {
                  this.router.navigate(['request-reset-password']);
                }
              }
            ]
          });
          await alert.present();

          return throwError(error);
        }),
        finalize(() => {
          this.submitted = false;
        })
      )
      .subscribe(async () => {
        const alert = await this.alertController.create({
          header: 'Réinitialisation du mot de passe',
          message: 'Votre mot de passe a bien été changé ! Vous pouvez désormais vous reconnecter.',
          buttons: [
            {
              text: 'J\'ai compris',
              handler: () => {
                this.router.navigate(['login']);
              }
            }
          ]
        });
        if (!this.error) {
          await alert.present();
        }
      });
  }
}
