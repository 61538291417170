<ion-header translucent>
  <ion-toolbar>
    <ion-title>Conditions Générales d'utilisation</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">Fermer</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen padding>
  <ion-text>
    <div [innerHTML]="contentCGUHTML"></div>
  </ion-text>
</ion-content>
