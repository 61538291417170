/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/common";
import * as i4 from "./form-error.component";
var styles_FormErrorComponent = [];
var RenderType_FormErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormErrorComponent, data: {} });
export { RenderType_FormErrorComponent as RenderType_FormErrorComponent };
function View_FormErrorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errorMessages[_v.parent.context.$implicit.key] || _v.parent.context.$implicit.key); _ck(_v, 1, 0, currVal_0); }); }
function View_FormErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "ion-text", [["color", "danger"]], null, null, null, i1.View_IonText_0, i1.RenderType_IonText)), i0.ɵdid(1, 49152, null, 0, i2.IonText, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], { color: [0, "color"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_FormErrorComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "danger"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.field.touched; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FormErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_FormErrorComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i3.KeyValuePipe, [i0.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.field.errors)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FormErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-form-error", [], null, null, null, View_FormErrorComponent_0, RenderType_FormErrorComponent)), i0.ɵdid(1, 114688, null, 0, i4.FormErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormErrorComponentNgFactory = i0.ɵccf("app-form-error", i4.FormErrorComponent, View_FormErrorComponent_Host_0, { field: "field", messages: "messages" }, {}, []);
export { FormErrorComponentNgFactory as FormErrorComponentNgFactory };
