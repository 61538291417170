/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confidentiality-agreement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./confidentiality-agreement.component";
import * as i5 from "@angular/platform-browser";
var styles_ConfidentialityAgreementComponent = [i0.styles];
var RenderType_ConfidentialityAgreementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfidentialityAgreementComponent, data: {} });
export { RenderType_ConfidentialityAgreementComponent as RenderType_ConfidentialityAgreementComponent };
export function View_ConfidentialityAgreementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-header", [["translucent", ""]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { translucent: [0, "translucent"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Protection des donn\u00E9es"])), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(8, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(10, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Fermer"])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "ion-content", [["fullscreen", ""], ["padding", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(13, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fullscreen: [0, "fullscreen"] }, null), i1.ɵdid(14, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "ion-text", [], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(16, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = ""; _ck(_v, 13, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.contentCAHTML; _ck(_v, 17, 0, currVal_2); }); }
export function View_ConfidentialityAgreementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confidentiality-agreement", [], null, null, null, View_ConfidentialityAgreementComponent_0, RenderType_ConfidentialityAgreementComponent)), i1.ɵdid(1, 114688, null, 0, i4.ConfidentialityAgreementComponent, [i3.ModalController, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfidentialityAgreementComponentNgFactory = i1.ɵccf("app-confidentiality-agreement", i4.ConfidentialityAgreementComponent, View_ConfidentialityAgreementComponent_Host_0, { contentCA: "contentCA" }, {}, []);
export { ConfidentialityAgreementComponentNgFactory as ConfidentialityAgreementComponentNgFactory };
