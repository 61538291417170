import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

export interface FilterInterface {
  pageSize?: string;
  page?: string;
  search?: string;
  sort?: string;
  type?: string;
}

export interface MessageInterface {
  message: string;
}

export abstract class AbstractRepository<T, ConstantsModel = undefined> {
  protected readonly baseUrl;

  protected constructor(protected readonly http: HttpClient) {
    this.baseUrl = environment.apiUrl;
  }

  abstract getModelName(): string;

  deleteById(id: number): Observable<void> {
    return this.http.delete<void>([this.baseUrl, this.getModelName(), id].join('/'));
  }

  findById(id: number): Observable<T> {
    return this.http.get<T>([this.baseUrl, this.getModelName(), id].join('/'));
  }

  create(data: T): Observable<T> {
    return this.http.post<T>([this.baseUrl, this.getModelName()].join('/'), data);
  }

  update(id: number, data: T): Observable<T> {
    return this.http.post<T>([this.baseUrl, this.getModelName(), id].join('/'), data);
  }

  find(filter: FilterInterface): Observable<Array<T>> {
    const params = new HttpParams({
      fromObject: filter as {[param: string]: string}
    });

    return this.http.get<Array<T>>([this.baseUrl, this.getModelName()].join('/'), {params});
  }

  list(): Observable<Array<T>> {
    return this.http.get<Array<T>>([this.baseUrl, this.getModelName()].join('/'));
  }

  constants(): Observable<ConstantsModel> {
    return this.http.get<ConstantsModel>([this.baseUrl, 'constants', this.getModelName()].join('/'));
  }
}
