import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TeamGroup } from '../models/teamGroup';
import { TeamGroupRepository } from '../repositories/team-group.repository';

@Injectable()
export class TeamGroupResolver implements Resolve<Array<TeamGroup>> {
    constructor(
        private readonly teamGroupRepository: TeamGroupRepository,
        private readonly router: Router,
        private readonly toastController: ToastController
    ) {
        this.handleError = this.handleError.bind(this);
    }

    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<Array<TeamGroup>> {
        return this.teamGroupRepository.getTeamGroup()
            .pipe(
                catchError(() => this.handleError())
            );
    }

    private handleError(): Observable<Array<TeamGroup>> {
        this.toastController.create({
            message: 'Erreur de récupération de la page',
            duration: 2000
        })
            .then(t => t.present());

        return of();
    }
}
