import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class StripeRepository extends AbstractRepository<any> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  generateStripeSessionId(refreshToken: string, quantity: number): Observable<{ stripeSessionId: string }> {
    // tslint:disable-next-line:max-line-length
    return this.http.post<{stripeSessionId: string}>([this.baseUrl, 'users', 'me', 'generate-stripe-session'].join('/'), {
      refreshToken,
      quantity,
      // Temporary fix to test payment on iOS
      locationOrigin: window.location.origin.replace('ionic://localhost', 'https://mycharlotte.co')
    });
  }

  attachSubscription(sessionId: string): Observable<any> {
    return this.http.post<any>([this.baseUrl, 'users', 'me', 'attach-subscription', sessionId].join('/'), {});
  }

  getModelName(): string {
    return 'stripe';
  }
}
