export const fr = {
  lang: 'fr',
  translations: {
    manup: {
      mandatory: {
        title: 'Une mise à jour est requise',
        text: 'Une mise à jour vers {{app}} est requise pour continuer.'
      },
      optional: {
        title: 'Mise à jour disponible',
        text: 'Une mise à jour vers {{app}} est disponible. Voulez-vous mettre à jour ?'
      },
      maintenance: {
        title: '{{app}} indisponible',
        text: "{{app}} est pour l'instant indisponible, merci de revenir dans quelques instants."
      },
      buttons: {
        update: 'Mettre à jour',
        later: 'Plus tard'
      }
    }
  }
};
