import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Category } from '../models/category';
import { CategoriesRepository } from '../repositories/categories.repository';

@Injectable()
export class ActivityCategoryResolver implements Resolve<Category> {
  constructor(
    private readonly router: Router,
    private readonly toastController: ToastController,
    private readonly activityLibrariesRepository: CategoriesRepository
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Category> {
    const code = route.paramMap.get('categoryCode');

    return this.activityLibrariesRepository.findByCode(code)
      .pipe(
        catchError(() => this.handleError())
      );
  }

  private handleError(): Observable<Category> {
    this.toastController.create({
      message: 'Impossible de récupérer les catégories d\'activités',
      duration: 2000
    })
      .then(t => t.present());
    this.router.navigate(['..']);

    return of();
  }
}
