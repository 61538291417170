import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from './authentication.service';

import { ToastController } from '@ionic/angular';
import { TokenService } from './token.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private readonly apiUrl: string;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly tokenService: TokenService,
    private readonly router: Router,
    private readonly toastController: ToastController
  ) {
    this.apiUrl = environment.apiUrl;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // don't override request if host is not api
    if (!req.url.startsWith(this.apiUrl)) {
      return next.handle(req);
    }

    // don't override requestResetPassword authentication request
    if (req.url === [this.apiUrl, 'login_check'].join('/')) {
      return next.handle(req);
    }

    // don't override refresh token request
    if (req.url === [this.apiUrl, 'token/refresh'].join('/')) {
      return next.handle(req);
    }

    // don't override enable user request
    if (req.url.startsWith([this.apiUrl, 'users/enable-user'].join('/'))) {
      return next.handle(req);
    }

    // don't override if no token is available
    if (!this.tokenService.getToken()) {
      return next.handle(req);
    }

    // Handle requests
    return next.handle(this.addTokenToRequest(req))
      .pipe(
      catchError(error => this.handleAuthError(req, error, next))
    );
  }

  private addTokenToRequest(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({ headers: req.headers.set('Authorization', `Bearer ${this.tokenService.getToken().encodedToken}`) });
  }

  private handleAuthError(req: HttpRequest<any>, error: HttpErrorResponse, next): Observable<any> {
    if (error.status === 0) {

      this.toastController.create({
        message: 'Vous êtes hors ligne, veuillez vérifier votre connectivité',
        duration: 2000,
        buttons: [
          {
            text: 'Rafraîchir',
            icon: 'refresh',
            handler: () => {
              window.location.reload();
            }
          }
        ]
      })
        .then(t => t.present());

      return of(error.message);
    }

    if (error.status === 500) {
      this.toastController.create({
        message: 'Une erreur serveur est survenue, veuillez contacter l\'administrateur',
        duration: 2000
      })
        .then(t => t.present());

      return of(error.message);
    }

    if (error.status === 403) {
      this.toastController.create({
        message: 'Vous n\'avez pas le droit d\'accéder à cette ressource',
        duration: 2000
      })
        .then(t => t.present());

      return of(error.message);
    }

    if (error.status === 402) {
      this.router.navigate(['/payment']);

      return of(error.message);
    }

    if (error.status === 401) {
      if (!this.isRefreshingToken) {
        this.isRefreshingToken = true;
        this.tokenSubject.next(undefined);

        return this.authenticationService.refreshToken()
          .pipe(
            switchMap(decodedToken => {
              next.handle(this.addTokenToRequest(req));

              // Force le rafraichissement de la page pour gérer les composants où un composant sollicite plusieurs resolvers
              window.location.reload();

              return decodedToken;
            }),
            catchError(() => {
              this.handleLogout();

              return throwError(error);
            }),
            finalize(() => {
              this.isRefreshingToken = false;
            })
          );
      }

      this.isRefreshingToken = false;

      return this.tokenSubject
        .pipe(
          filter(token => token !== undefined),
          take(1),
          switchMap(() =>
            next.handle(this.addTokenToRequest(req)))
        );
    }

    return throwError(error);
  }

  private handleLogout(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    this.toastController.create({
      message: 'Vous avez été déconnectée, veuillez vous reconnecter.',
      duration: 2000
    })
      .then(t => t.present());
  }
}
