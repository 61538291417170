/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-effect-selector.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./side-effect-selector.page";
var styles_SideEffectSelectorPage = [i0.styles];
var RenderType_SideEffectSelectorPage = i1.ɵcrt({ encapsulation: 0, styles: styles_SideEffectSelectorPage, data: {} });
export { RenderType_SideEffectSelectorPage as RenderType_SideEffectSelectorPage };
function View_SideEffectSelectorPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ion-item", [], null, null, null, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "ion-label", [], null, null, null, i2.View_IonLabel_0, i2.RenderType_IonLabel)), i1.ɵdid(3, 49152, null, 0, i3.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(4, 0, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "ion-checkbox", [["slot", "start"]], null, [[null, "ionChange"], [null, "ionBlur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ionBlur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleBlurEvent($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("ionChange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8)._handleIonChange($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("ionChange" === en)) {
        var pd_2 = (_co.toggle(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_IonCheckbox_0, i2.RenderType_IonCheckbox)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.BooleanValueAccessor]), i1.ɵdid(7, 49152, null, 0, i3.IonCheckbox, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { checked: [0, "checked"] }, null), i1.ɵdid(8, 16384, null, 0, i3.BooleanValueAccessor, [i1.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.selectedSideEffectIds || i1.ɵEMPTY_ARRAY).includes(_v.context.$implicit.id); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_0); }); }
export function View_SideEffectSelectorPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-header", [["translucent", ""]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { translucent: [0, "translucent"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["S\u00E9lection des sympt\u00F4mes"])), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(8, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "ion-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(10, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Fermer"])), (_l()(), i1.ɵeld(12, 0, null, null, 9, "ion-content", [["fullscreen", ""], ["padding", ""]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(13, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fullscreen: [0, "fullscreen"] }, null), i1.ɵdid(14, 16384, null, 0, i3.CssUtilsDeprecations, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "ion-text", [], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(16, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, [" S\u00E9l\u00E9ctionnez les sympt\u00F4mes. "])), (_l()(), i1.ɵeld(18, 0, null, 0, 3, "ion-list", [], null, null, null, i2.View_IonList_0, i2.RenderType_IonList)), i1.ɵdid(19, 49152, null, 0, i3.IonList, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SideEffectSelectorPage_1)), i1.ɵdid(21, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = ""; _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.sideEffects; _ck(_v, 21, 0, currVal_2); }, null); }
export function View_SideEffectSelectorPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-effect-selector", [], null, null, null, View_SideEffectSelectorPage_0, RenderType_SideEffectSelectorPage)), i1.ɵdid(1, 49152, null, 0, i6.SideEffectSelectorPage, [i3.ModalController], null, null)], null, null); }
var SideEffectSelectorPageNgFactory = i1.ɵccf("app-side-effect-selector", i6.SideEffectSelectorPage, View_SideEffectSelectorPage_Host_0, { sideEffects: "sideEffects", selectedSideEffectIds: "selectedSideEffectIds", addOrRemoveSideEffect: "addOrRemoveSideEffect" }, {}, []);
export { SideEffectSelectorPageNgFactory as SideEffectSelectorPageNgFactory };
