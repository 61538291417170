import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Activity } from '../models/activity';
import { ActivitiesRepository } from '../repositories/activities.repository';

@Injectable()
export class ActivitiesByLengthResolver implements Resolve<Array<Activity>> {
  constructor(
    private readonly activitiesRepository: ActivitiesRepository,
    private readonly router: Router,
    private readonly toastController: ToastController
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Array<Activity>> {
    const length = route.paramMap.get('length');

    return this.activitiesRepository.getActivitiesByLength(length)
      .pipe(
        catchError(() => this.handleError())
    );
  }

  private handleError(): Observable<Array<Activity>> {
    this.toastController.create({
      message: 'Impossible de récupérer les catégories d\'activités',
      duration: 2000
    })
      .then(t => t.present());

    return of();
  }
}
