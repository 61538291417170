import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastController } from '@ionic/angular';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly toastController: ToastController
  ) {
    route.params.subscribe(async () => {
      await this.authService.logout();
      const toast = await this.toastController.create({
        message: 'Vous êtes désormais déconnectée',
        duration: 2000
      });
      toast.present();
      await this.router.navigate(['/login']);
    });
  }
}
