import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MomentModule } from 'ngx-moment';
import { FormErrorComponent } from '../core/form-error/form-error.component';
import { TextareaAutoresize } from '../core/utils/autoresize.directive';
import { ConvertToMinSecPipe } from '../core/utils/convert-to-min-sec.pipe';
import { MediasPipe } from '../core/utils/medias.pipe';
import { PasswordToggleDirective } from '../core/utils/password-toggle.directive';
import { SafeHtmlPipe } from '../core/utils/safe-html.pipe';
import { DailySurveyComponent } from './daily-survey/daily-survey.component';
import { SideEffectSelectorPage } from './daily-survey/side-effect-selector/side-effect-selector.page';

@NgModule({
  declarations: [
    TextareaAutoresize,
    MediasPipe,
    PasswordToggleDirective,
    SafeHtmlPipe,
    ConvertToMinSecPipe,
    FormErrorComponent,
    DailySurveyComponent,
    SideEffectSelectorPage
  ],
  imports: [
    CommonModule,
    IonicModule,
    FlexModule,
    MomentModule,
    ReactiveFormsModule
  ],
  exports: [
    TextareaAutoresize,
    MediasPipe,
    SafeHtmlPipe,
    ConvertToMinSecPipe,
    FormErrorComponent,
    PasswordToggleDirective
  ],
  entryComponents: [
    SideEffectSelectorPage
  ]
})
export class SharedModule {}
