<ion-content scrollY="true">
  <div class="login-wrapper" fxLayoutAlign="center center">
    <div fxLayoutAlign.gt-xs="center center" fxFlexFill>
      <ion-card class="login">
        <div class="login-header">
          Bienvenue chez myCharlotte !
        </div>
        <ion-card-content class="login-content">
          <img class="login-icon" src="assets/images/logo.png" alt="myCharlotte"/>
          <form fxLayout="column" fxLayoutGap="10px" [formGroup]="loginForm" (submit)="login()" (keyup.enter)="login()" novalidate>
            <ion-item *ngIf="formError" color="danger">
              <ion-text>
                Identifiants incorrectes
              </ion-text>
            </ion-item>

            <div>
              <ion-item>
                <ion-label position="stacked">Email <ion-text color="danger">*</ion-text></ion-label>
                <ion-input
                  type="text"
                  required
                  autocorrect="none"
                  autocapitalize="none"
                  formControlName="username"
                  placeholder="charlotte@mycharlotte.fr"
                  clearInput
                ></ion-input>
              </ion-item>
              <ion-text *ngIf="formError || (loginForm.get('username').invalid && loginForm.get('username').touched)" color="danger">{{ getUsernameErrorMessage() }}</ion-text>
            </div>

            <div>
              <ion-item>
                <ion-label position="stacked">Mot de passe <ion-text color="danger">*</ion-text></ion-label>
                <ion-input
                    type="password"
                    required
                    autocorrect="none"
                    autocapitalize="none"
                    formControlName="password"
                    placeholder="Mot de passe"
                    clearInput
                    #toggleInput
                ></ion-input>
                <ion-button type="button" color="light" class="my-auto" slot="end" passwordToggle [targetInput]="toggleInput">
                  <ion-icon name="eye"></ion-icon>
                </ion-button>
              </ion-item>
              <ion-text *ngIf="formError || (loginForm.get('password').invalid && loginForm.get('password').touched)" color="danger">{{ getPasswordErrorMessage() }}</ion-text>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" class="login-button">
              <ion-button
                color="primary"
                type="submit"
                [disabled]="submitted"
              >
                S'identifier
              </ion-button>
            </div>
          </form>
          <hr class="login-form-divider" />
          <ion-label>
            <h2>Vous n'êtes pas encore inscrite ?</h2>
          </ion-label>
          <div fxLayout="row" fxLayoutAlign="center center" class="login-button">
            <ion-button
                type="button"
                fill="outline"
                color="primary"
                [routerLink]="['/register']"
            >
              S'inscrire
            </ion-button>
          </div>

          <hr class="login-form-divider" />
          <ion-label>
            <h2>Vous avez oublié votre mot de passe ?</h2>
          </ion-label>
          <div fxLayout="row" fxLayoutAlign="center center" class="login-button">
            <ion-button
                type="button"
                fill="outline"
                color="primary"
                [routerLink]="['/request-reset-password']"
            >
              Réinitialisez votre mot de passe
            </ion-button>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </div>
</ion-content>
