import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var SideEffectsRepository = /** @class */ (function (_super) {
    tslib_1.__extends(SideEffectsRepository, _super);
    function SideEffectsRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    SideEffectsRepository.prototype.getModelName = function () {
        return 'side-effects';
    };
    SideEffectsRepository.prototype.getRecurringSideEffects = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'recurring'].join('/'));
    };
    SideEffectsRepository.prototype.getSideEffectsWithTargetedActivities = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'with-targeted-activities'].join('/'));
    };
    SideEffectsRepository.prototype.getNonRecurringSideEffects = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'non-recurring'].join('/'));
    };
    return SideEffectsRepository;
}(AbstractRepository));
export { SideEffectsRepository };
