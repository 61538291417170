import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var UserContextStepRepository = /** @class */ (function (_super) {
    tslib_1.__extends(UserContextStepRepository, _super);
    function UserContextStepRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    UserContextStepRepository.prototype.ignoreUserContextStep = function (id) {
        return this.http.post([this.baseUrl, this.getModelName(), id, 'ignore'].join('/'), {});
    };
    UserContextStepRepository.prototype.validateUserContextStep = function (id) {
        return this.http.post([this.baseUrl, this.getModelName(), id, 'validate'].join('/'), {});
    };
    UserContextStepRepository.prototype.getModelName = function () {
        return 'user-context-steps';
    };
    return UserContextStepRepository;
}(AbstractRepository));
export { UserContextStepRepository };
