import { Directive, HostListener, Input } from '@angular/core';

/**
 * Generated class for the PasswordToggleDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Directive({
  selector: '[passwordToggle]' // Attribute selector
})
export class PasswordToggleDirective {
  @Input('targetInput') targetInput: any;

  @HostListener('click') onMouseEnter(): void {
    console.log(this.targetInput);
    this.targetInput.el.type = (this.targetInput.el.type === 'text') ? 'password' : 'text';
  }
}
