import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SideEffect } from '../models/side-effect';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class SideEffectsRepository extends AbstractRepository<SideEffect> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  getModelName(): string {
    return 'side-effects';
  }

  getRecurringSideEffects(): Observable<Array<SideEffect>> {
    return this.http.get<Array<SideEffect>>([this.baseUrl, this.getModelName(), 'recurring'].join('/'));
  }

  getSideEffectsWithTargetedActivities(): Observable<Array<SideEffect>> {
    return this.http.get<Array<SideEffect>>([this.baseUrl, this.getModelName(), 'with-targeted-activities'].join('/'));
  }

  getNonRecurringSideEffects(): Observable<Array<SideEffect>> {
    return this.http.get<Array<SideEffect>>([this.baseUrl, this.getModelName(), 'non-recurring'].join('/'));
  }

}
