import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var AuthorsRepository = /** @class */ (function (_super) {
    tslib_1.__extends(AuthorsRepository, _super);
    function AuthorsRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    AuthorsRepository.prototype.getModelName = function () {
        return 'authors';
    };
    AuthorsRepository.prototype.getInLibraryAuthors = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'in-library'].join('/'));
    };
    AuthorsRepository.prototype.getAuthorActivities = function (id) {
        return this.http.get([this.baseUrl, this.getModelName(), id, 'activities'].join('/'));
    };
    return AuthorsRepository;
}(AbstractRepository));
export { AuthorsRepository };
