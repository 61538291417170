import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var StripeRepository = /** @class */ (function (_super) {
    tslib_1.__extends(StripeRepository, _super);
    function StripeRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    StripeRepository.prototype.generateStripeSessionId = function (refreshToken, quantity) {
        // tslint:disable-next-line:max-line-length
        return this.http.post([this.baseUrl, 'users', 'me', 'generate-stripe-session'].join('/'), {
            refreshToken: refreshToken,
            quantity: quantity,
            // Temporary fix to test payment on iOS
            locationOrigin: window.location.origin.replace('ionic://localhost', 'https://mycharlotte.co')
        });
    };
    StripeRepository.prototype.attachSubscription = function (sessionId) {
        return this.http.post([this.baseUrl, 'users', 'me', 'attach-subscription', sessionId].join('/'), {});
    };
    StripeRepository.prototype.getModelName = function () {
        return 'stripe';
    };
    return StripeRepository;
}(AbstractRepository));
export { StripeRepository };
