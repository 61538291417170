import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserActivityHistory } from '../models/user-activity-history';
import { UserActivityHistoryRepository } from '../repositories/user-activity-history.repository';

@Injectable()
export class UserActivityHistoriesResolver implements Resolve<Array<UserActivityHistory>> {
  constructor(
    private readonly userActivityHistoryRepository: UserActivityHistoryRepository,
    private readonly router: Router,
    private readonly toastController: ToastController
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Array<UserActivityHistory>> {
    return this.userActivityHistoryRepository.list()
      .pipe(
        catchError(() => this.handleError())
    );
  }

  private handleError(): Observable<Array<UserActivityHistory>> {
    this.toastController.create({
      message: 'Erreur de récupération de l\'historique',
      duration: 2000
    })
      .then(t => t.present());

    return of();
  }
}
