import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthorsRepository } from '../../repositories/authors.repository';
import { BenefitsRepository } from '../../repositories/benefits.repository';
import { LibraryRepository } from '../../repositories/library.repository';
import { TeamGroupRepository } from '../../repositories/team-group.repository';
import { UserRepository } from '../../repositories/user.repository';
import { ActivitiesByLengthResolver } from '../../resolvers/activities-by-length.resolver';
import { AuthorActivitiesResolver } from '../../resolvers/author-activities.resolver';
import { ActivityAuthorResolver } from '../../resolvers/author.resolver';
import { AuthorsResolver } from '../../resolvers/authors.resolver';
import { BenefitActivitiesResolver } from '../../resolvers/benefit-activities.resolver';
import { ActivityBenefitResolver } from '../../resolvers/benefit.resolver';
import { ActivityBenefitsResolver } from '../../resolvers/benefits.resolver';
import { FavoriteActivitiesResolver } from '../../resolvers/favorite-activities.resolver';
import { LibraryResolver } from '../../resolvers/library.resolver';
import { SideEffectActivitiesResolver } from '../../resolvers/side-effect-activities.resolver';
import { SideEffectsWithTargetedActivitiesResolver } from '../../resolvers/side-effects-with-targeted-activities.resolver';
import { TeamGroupResolver } from '../../resolvers/team-group-resolver';
import { LoginComponent } from './login/login.component';

import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { MomentModule } from 'ngx-moment';
import { ActivitiesRepository } from '../../repositories/activities.repository';
import { CategoriesRepository } from '../../repositories/categories.repository';
import { SideEffectsRepository } from '../../repositories/side-effects.repository';
import { StatsRepository } from '../../repositories/stats.repository';
import { StripeRepository } from '../../repositories/stripe.repository';
import { TreatmentDatesRepository } from '../../repositories/treatment-dates.repository';
import { TreatmentsRepository } from '../../repositories/treatments.repository';
import { UserActivityHistoryRepository } from '../../repositories/user-activity-history.repository';
import { UserContextStepRepository } from '../../repositories/user-context-step.repository';
import { UserContextRepository } from '../../repositories/user-context.repository';
import { ActivitiesConstantsResolver } from '../../resolvers/activities-constants.resolver';
import { ActivityResolver } from '../../resolvers/activity.resolver';
import { ActivityCategoriesResolver } from '../../resolvers/categories.resolver';
import { CategoryActivitiesResolver } from '../../resolvers/category-activities.resolver';
import { ActivityCategoryResolver } from '../../resolvers/category.resolver';
import { LastDailySurveyResolver } from '../../resolvers/last-daily-survey.resolver';
import { NonRecurringSideEffectsResolver } from '../../resolvers/non-recurring-side-effects.resolver';
import { RecurringSideEffectsResolver } from '../../resolvers/recurring-side-effects.resolver';
import { SideEffectsResolver } from '../../resolvers/side-effects.resolver';
import { TreatmentDateActivitiesResolver } from '../../resolvers/treatment-date-activities.resolver';
import { TreatmentDateResolver } from '../../resolvers/treatment-date.resolver';
import { TreatmentResolver } from '../../resolvers/treatment.resolver';
import { TreatmentsConstantsResolver } from '../../resolvers/treatments-constants.resolver';
import { UserActivityHistoriesResolver } from '../../resolvers/user-activity-histories.resolver';
import { UserLastHistoryActivitiesResolver } from '../../resolvers/user-last-history-activities.resolver';
import { UsersConstantsResolver } from '../../resolvers/users-constants.resolver';
import { UsersMeResolver } from '../../resolvers/users-me.resolver';
import { UsersSubscriptionResolver } from '../../resolvers/users-subscription.resolver';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationGuardService } from './authentication/authentication-guard.service';
import { AuthenticationInterceptor } from './authentication/authentication-interceptor';
import { AuthenticationService } from './authentication/authentication.service';
import { TokenService } from './authentication/token.service';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { LogoutComponent } from './logout/logout.component';
import { AmountSelectorComponent } from './payment/amount-selector/amount-selector.component';
import { PaymentComponent } from './payment/payment.component';
import { CGUPage } from './register/cgu/cgu.page';
import { ConfidentialityAgreementComponent } from './register/confidentiality-agreement/confidentiality-agreement.component';
import { RegisterComponent } from './register/register.component';
import { RequestResetPasswordComponent } from './request-reset-password/request-reset-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    PaymentComponent,
    ConfirmRegistrationComponent,
    RequestResetPasswordComponent,
    ResetPasswordComponent,
    CGUPage,
    ConfidentialityAgreementComponent,
    AmountSelectorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    FlexLayoutModule,
    IonicModule,
    IonicStorageModule,
    ReactiveFormsModule,
    SharedModule,
    MomentModule,
    FormsModule
  ],
  providers: [
    AuthenticationGuardService,
    UsersConstantsResolver,
    UsersSubscriptionResolver,
    UserActivityHistoriesResolver,
    UserLastHistoryActivitiesResolver,
    ActivityCategoriesResolver,
    ActivityCategoryResolver,
    CategoryActivitiesResolver,
    ActivitiesConstantsResolver,
    ActivityResolver,
    UsersMeResolver,
    LastDailySurveyResolver,
    RecurringSideEffectsResolver,
    SideEffectsResolver,
    NonRecurringSideEffectsResolver,
    TreatmentResolver,
    TreatmentsConstantsResolver,
    TeamGroupResolver,
    ActivityBenefitsResolver,
    BenefitActivitiesResolver,
    LibraryResolver,
    AuthorsResolver,
    AuthorActivitiesResolver,
    ActivityAuthorResolver,
    ActivitiesByLengthResolver,
    SideEffectsWithTargetedActivitiesResolver,
    SideEffectActivitiesResolver,
    ActivityBenefitResolver,
    FavoriteActivitiesResolver,
    TreatmentDateActivitiesResolver,
    TreatmentDateResolver,
    TokenService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    AuthenticationService,
    UserRepository,
    ActivitiesRepository,
    SideEffectsRepository,
    StripeRepository,
    CategoriesRepository,
    TreatmentsRepository,
    TreatmentDatesRepository,
    UserContextRepository,
    UserActivityHistoryRepository,
    StatsRepository,
    UserContextStepRepository,
    TeamGroupRepository,
    BenefitsRepository,
    LibraryRepository,
    AuthorsRepository
  ],
  exports: [],
  entryComponents: [
    CGUPage,
    ConfidentialityAgreementComponent
  ]
})
export class CoreModule {
}
