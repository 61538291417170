import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { UserRepository } from '../../../repositories/user.repository';
import { TokenService } from './token.service';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(userRepository, tokenService, toastController) {
        this.userRepository = userRepository;
        this.tokenService = tokenService;
        this.toastController = toastController;
    }
    AuthenticationService.prototype.login = function (username, password) {
        var _this = this;
        return this.userRepository.login(username, password)
            .pipe(map(function (_a) {
            var token = _a.token, refresh_token = _a.refresh_token;
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var decodedToken;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.tokenService.loadToken(token, refresh_token)];
                        case 1:
                            _b.sent();
                            decodedToken = this.tokenService.getToken().decodedToken;
                            this.toastController.create({
                                message: "Vous \u00EAtes d\u00E9sormais connect\u00E9e en tant que " + decodedToken.email,
                                duration: 2000
                            })
                                .then(function (t) { return t.present(); });
                            return [2 /*return*/, decodedToken];
                    }
                });
            });
        }, function (error) {
            console.error(error);
            return error;
        }));
    };
    AuthenticationService.prototype.refreshToken = function (quite) {
        var _this = this;
        if (quite === void 0) { quite = false; }
        var currentToken = this.tokenService.getToken();
        return this.userRepository.refreshToken(currentToken.refreshToken)
            .pipe(map(function (_a) {
            var token = _a.token, refresh_token = _a.refresh_token;
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var decodedToken;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.tokenService.loadToken(token, refresh_token)];
                        case 1:
                            _b.sent();
                            decodedToken = this.tokenService.getToken().decodedToken;
                            if (!quite) {
                                this.toastController.create({
                                    message: "Vous \u00EAtes de nouveau connect\u00E9\u00B7e en tant que " + decodedToken.email,
                                    duration: 2000
                                })
                                    .then(function (t) { return t.present(); });
                            }
                            return [2 /*return*/, decodedToken];
                    }
                });
            });
        }));
    };
    AuthenticationService.prototype.logout = function (message) {
        if (message === void 0) { message = 'Vous avez bien été déconnectée'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.tokenService.cleanStorage()];
                    case 1:
                        _a.sent();
                        this.toastController.create({
                            message: message,
                            duration: 2000
                        })
                            .then(function (t) { return t.present(); });
                        return [2 /*return*/];
                }
            });
        });
    };
    return AuthenticationService;
}());
export { AuthenticationService };
