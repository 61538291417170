import { Component, NgZone } from '@angular/core';

import { Router } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import * as d3 from 'd3';
import * as fr from 'd3-time-format/locale/fr-FR.json';
import moment from 'moment';
import RangeTouch from 'rangetouch';
import { ConfirmRegistrationComponent } from './core/confirm-registration/confirm-registration.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { ManUpService } from './core/utils/manup/manup.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private readonly platform: Platform,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
    private readonly angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private readonly deeplinks: Deeplinks,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly manup: ManUpService
  ) {
    this.initializeApp();
    angulartics2GoogleAnalytics.startTracking();
    moment.locale('fr');
    d3.timeFormatDefaultLocale({
        dateTime: '%A, le %e %B %Y, %X',
        date: '%d/%m/%Y',
        time: '%H:%M:%S',
        periods: ['AM', 'PM'],
        days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        shortMonths: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']
      }
    );
    // tslint:disable-next-line:no-unused-expression
    new RangeTouch('input[type="range"]');

    this.deeplinks.route({
      '/confirm-registration': ConfirmRegistrationComponent,
      '/reset-password': ResetPasswordComponent
    })
      .subscribe(match => {
        this.zone.run(() => {
          this.router.navigate([match.$link.path], {queryParams: match.$args});
        });
      });
  }

  initializeApp(): void {
    this.platform.ready()
      .then(async () => {
        this.statusBar.styleDefault();
        this.splashScreen.hide();

        this.manup.validate()
          .then(() => {
        });
      });
  }
}
