import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TokenService } from './token.service';

@Injectable()
export class AuthenticationGuardService implements CanActivate {
  constructor(
    private readonly tokenService: TokenService,
    private readonly router: Router,
    private readonly toastController: ToastController
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.tokenService.getToken()) {
      await this.tokenService.loadStoredTokens();
    }
    await this.tokenService.loadQueryParamsTokens();

    const token = this.tokenService.getToken();

    if (!token) {
      this.toastController.create({
        message: 'Veuillez vous reconnecter pour accéder à cette page.',
        duration: 2000
      })
        .then(t => t.present());
      this.router.navigate(['login']);

      return false;
    }

    const subscriptionExpired = token.decodedToken.subscription === undefined || token.decodedToken.subscription * 1000 < (new Date()).getTime();
    const nextPaymentRequestExpired = token.decodedToken.nextPaymentRequest === undefined ||
      token.decodedToken.nextPaymentRequest * 1000 < (new Date()).getTime();

    if (state.url.startsWith('/payment')) {
      if (subscriptionExpired && nextPaymentRequestExpired) {
        return true;
      }

      this.router.navigate(['menu', 'programme']);

      return false;
    }

    if (subscriptionExpired && nextPaymentRequestExpired) {
      this.router.navigate(['/payment']);

      return false;
    }

    return true;
  }
}
