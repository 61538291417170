import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SideEffect } from '../../../../models/side-effect';

@Component({
  selector: 'app-side-effect-selector',
  templateUrl: './side-effect-selector.page.html',
  styleUrls: ['./side-effect-selector.page.scss']
})
export class SideEffectSelectorPage {
  @Input() sideEffects: Array<SideEffect>;
  @Input() selectedSideEffectIds: Array<number>;
  @Input() addOrRemoveSideEffect: (sideEffect: SideEffect) => void;

  constructor(private readonly modalController: ModalController) {}

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  toggle(sideEffect: SideEffect): void {
    this.addOrRemoveSideEffect(sideEffect);
  }
}
