import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var StatsRepository = /** @class */ (function (_super) {
    tslib_1.__extends(StatsRepository, _super);
    function StatsRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    StatsRepository.prototype.getModelName = function () {
        return 'stats';
    };
    StatsRepository.prototype.getStats = function () {
        return this.http.get([this.baseUrl, 'stats'].join('/'));
    };
    StatsRepository.prototype.getActivitiesByCategoryStats = function (startDate, endDate) {
        var params = new HttpParams({
            fromObject: {
                startDate: startDate,
                endDate: endDate
            }
        });
        return this.http.get([this.baseUrl, 'stats', 'activities-by-category'].join('/'), { params: params });
    };
    StatsRepository.prototype.getActivitiesStats = function (startDate, endDate) {
        var params = new HttpParams({
            fromObject: {
                startDate: startDate,
                endDate: endDate
            }
        });
        return this.http.get([this.baseUrl, 'stats', 'activities'].join('/'), { params: params });
    };
    StatsRepository.prototype.getSideEffectsStats = function (startDate, endDate) {
        var params = new HttpParams({
            fromObject: {
                startDate: startDate,
                endDate: endDate
            }
        });
        return this.http.get([this.baseUrl, 'stats', 'side-effects'].join('/'), { params: params });
    };
    StatsRepository.prototype.getTreatmentDatesStats = function (startDate, endDate) {
        var params = new HttpParams({
            fromObject: {
                startDate: startDate,
                endDate: endDate
            }
        });
        return this.http.get([this.baseUrl, 'stats', 'treatment-dates'].join('/'), { params: params });
    };
    return StatsRepository;
}(AbstractRepository));
export { StatsRepository };
