import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';
var AmountSelectorComponent = /** @class */ (function () {
    function AmountSelectorComponent() {
        this.onDestroy$ = new Subject();
    }
    AmountSelectorComponent.convertToIntegerAmount = function (amount) {
        return Math.max(+amount.split(' ')
            .shift(), 0);
    };
    AmountSelectorComponent.prototype.ngOnInit = function () {
        var amount = +this.contributionForm.get('contribution').value;
        if (AmountSelectorComponent.QUICK_AMOUNTS.includes(amount)) {
            this.otherAmountFocused = false;
        }
        else {
            this.otherAmountFocused = true;
            this.otherAmount = amount;
        }
        this.amount = amount;
        this.quickAmounts = AmountSelectorComponent.QUICK_AMOUNTS;
        this.updateAmounts();
        this.configureInput();
    };
    AmountSelectorComponent.prototype.onQuickAmountClick = function ($event, amount) {
        this.amount = +amount;
        this.otherAmount = undefined;
        this.updateAmounts();
    };
    AmountSelectorComponent.prototype.isQuickAmountChecked = function (quickAmount) {
        return (!this.otherAmountFocused || (this.otherAmountFocused && !this.otherAmount)) && !this.otherAmount && (this.amount === quickAmount);
    };
    AmountSelectorComponent.prototype.isOtherAmountInputChecked = function (otherAmount) {
        return otherAmount;
    };
    AmountSelectorComponent.prototype.updateAmounts = function () {
        this.contributionForm.patchValue({ amount: Math.max(this.amount, 0) });
    };
    AmountSelectorComponent.prototype.configureInput = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var input, maskedInput;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contributionInput.getInputElement()];
                    case 1:
                        input = _a.sent();
                        maskedInput = createTextMaskInputElement({
                            inputElement: input,
                            mask: createNumberMask({
                                prefix: '',
                                suffix: ' €/M'
                            })
                        });
                        this.contributionInput.ionChange
                            .pipe(takeUntil(this.onDestroy$))
                            .subscribe(function (event) {
                            var value = event.detail.value;
                            maskedInput.update(value);
                            _this.contributionInput.value = input.value;
                            if (value) {
                                _this.amount = AmountSelectorComponent.convertToIntegerAmount(value);
                                _this.updateAmounts();
                            }
                        });
                        input.focus();
                        return [2 /*return*/];
                }
            });
        });
    };
    AmountSelectorComponent.DEFAULT_AMOUNT = 10;
    AmountSelectorComponent.QUICK_AMOUNTS = [5, 10, 25, 50];
    return AmountSelectorComponent;
}());
export { AmountSelectorComponent };
