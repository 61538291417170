import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var UserContextRepository = /** @class */ (function (_super) {
    tslib_1.__extends(UserContextRepository, _super);
    function UserContextRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    UserContextRepository.prototype.getAvailableContexts = function () {
        return this.http.get([this.baseUrl, 'contexts', 'available'].join('/'));
    };
    UserContextRepository.prototype.setCurrentActiveContext = function (id) {
        return this.http.post([this.baseUrl, 'contexts', id, 'active'].join('/'), {});
    };
    UserContextRepository.prototype.getModelName = function () {
        return 'user-context';
    };
    return UserContextRepository;
}(AbstractRepository));
export { UserContextRepository };
