import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var TreatmentsRepository = /** @class */ (function (_super) {
    tslib_1.__extends(TreatmentsRepository, _super);
    function TreatmentsRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    TreatmentsRepository.prototype.getModelName = function () {
        return 'treatments';
    };
    return TreatmentsRepository;
}(AbstractRepository));
export { TreatmentsRepository };
