import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UsersConstantsResolver } from '../resolvers/users-constants.resolver';
import { AuthenticationGuardService } from './core/authentication/authentication-guard.service';
import { ConfirmRegistrationComponent } from './core/confirm-registration/confirm-registration.component';
import { LoginComponent } from './core/login/login.component';
import { LogoutComponent } from './core/logout/logout.component';
import { PaymentComponent } from './core/payment/payment.component';
import { RegisterComponent } from './core/register/register.component';
import { RequestResetPasswordComponent } from './core/request-reset-password/request-reset-password.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu/programme/tabs/program',
    pathMatch: 'full'
  },
  {
    path: 'menu',
    loadChildren: './pages/menu/menu.module#MenuPageModule',
    canActivate: [AuthenticationGuardService],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthenticationGuardService],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'confirm-registration',
    component: ConfirmRegistrationComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
    resolve: {
      constants: UsersConstantsResolver
    }
  },
  {
      path: 'request-reset-password',
      component: RequestResetPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
