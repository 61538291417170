import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var LibraryRepository = /** @class */ (function (_super) {
    tslib_1.__extends(LibraryRepository, _super);
    function LibraryRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    LibraryRepository.prototype.getModelName = function () {
        return 'library';
    };
    return LibraryRepository;
}(AbstractRepository));
export { LibraryRepository };
