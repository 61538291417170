import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TreatmentsConstants } from '../models/constants';
import { Treatment } from '../models/treatment';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class TreatmentsRepository extends AbstractRepository<Treatment, TreatmentsConstants> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  getModelName(): string {
    return 'treatments';
  }
}
