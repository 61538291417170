import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivitiesByCategoryStat, ActivitiesStat, SideEffectsStat, Stats, TreatmentDatesStat } from '../models/stats';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class StatsRepository extends AbstractRepository<Stats> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  getModelName(): string {
    return 'stats';
  }

  getStats(): Observable<Stats> {
    return this.http.get<Stats>([this.baseUrl, 'stats'].join('/'));
  }

  getActivitiesByCategoryStats(startDate: string, endDate: string): Observable<Array<ActivitiesByCategoryStat>> {
    const params = new HttpParams({
      fromObject: {
        startDate,
        endDate
      }
    });

    return this.http.get<Array<ActivitiesByCategoryStat>>([this.baseUrl, 'stats', 'activities-by-category'].join('/'), {params});
  }

  getActivitiesStats(startDate: string, endDate: string): Observable<Array<ActivitiesStat>> {
    const params = new HttpParams({
      fromObject: {
        startDate,
        endDate
      }
    });

    return this.http.get<Array<ActivitiesStat>>([this.baseUrl, 'stats', 'activities'].join('/'), {params});
  }

  getSideEffectsStats(startDate: string, endDate: string): Observable<Array<SideEffectsStat>> {
    const params = new HttpParams({
      fromObject: {
        startDate,
        endDate
      }
    });

    return this.http.get<Array<SideEffectsStat>>([this.baseUrl, 'stats', 'side-effects'].join('/'), {params});
  }

  getTreatmentDatesStats(startDate: string, endDate: string): Observable<Array<TreatmentDatesStat>> {
    const params = new HttpParams({
      fromObject: {
        startDate,
        endDate
      }
    });

    return this.http.get<Array<TreatmentDatesStat>>([this.baseUrl, 'stats', 'treatment-dates'].join('/'), {params});
  }
}
