import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SideEffect } from '../models/side-effect';
import { SideEffectsRepository } from '../repositories/side-effects.repository';

@Injectable()
export class SideEffectsResolver implements Resolve<Array<SideEffect>> {
  constructor(
    private readonly sideEffectRepository: SideEffectsRepository,
    private readonly router: Router,
    private readonly toastController: ToastController
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Array<SideEffect>> {
    return this.sideEffectRepository.list()
      .pipe(
        catchError(() => this.handleError())
    );
  }

  private handleError(): Observable<Array<SideEffect>> {
    this.toastController.create({
      message: 'Erreur de récupération de la page',
      duration: 2000
    })
      .then(t => t.present());

    return of();
  }
}
