<ion-header translucent>
  <ion-toolbar>
    <ion-title>Sélection des symptômes</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">Fermer</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content fullscreen padding>
  <ion-text>
    Séléctionnez les symptômes.
  </ion-text>
  <ion-list>
    <ion-item *ngFor="let sideEffect of sideEffects">
      <ion-label>{{ sideEffect.name }}</ion-label>
      <ion-checkbox (ionChange)="toggle(sideEffect)" slot="start" [checked]="(selectedSideEffectIds || []).includes(sideEffect.id)"></ion-checkbox>
    </ion-item>
  </ion-list>
</ion-content>
