import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity';
import { ConstantInterface } from '../models/constants';
import { TreatmentDate } from '../models/treatment';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class TreatmentDatesRepository extends AbstractRepository<TreatmentDate, ConstantInterface> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  getCurrentTreatments(): Observable<Array<TreatmentDate>> {
    return this.http.get<Array<TreatmentDate>>([this.baseUrl, 'users', 'me', 'treatments', 'currents'].join('/'));
  }

  getModelName(): string {
    return 'treatment-dates';
  }

  getTreatmentDateActivities(id: number): Observable<Array<Activity>> {
    return this.http.get<Array<Activity>>([this.baseUrl, this.getModelName(), id, 'activities'].join('/'));
  }
}
