import { Injectable } from '@angular/core';
import { Resolve} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DailySurvey } from '../models/daily-survey';
import { UserRepository } from '../repositories/user.repository';

@Injectable()
export class LastDailySurveyResolver implements Resolve<DailySurvey | null> {
  constructor(private readonly userRepository: UserRepository) {}

  resolve(): Observable<DailySurvey | null> {
    return this.userRepository.getLastDailySurvey()
      .pipe(
        catchError(() =>  of(undefined))
      );
  }
}
