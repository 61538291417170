import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { UserRepository } from '../../../repositories/user.repository';
var RequestResetPasswordComponent = /** @class */ (function () {
    function RequestResetPasswordComponent(formBuilder, route, userRepository, alertController, router) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.userRepository = userRepository;
        this.alertController = alertController;
        this.router = router;
        this.submitted = false;
    }
    RequestResetPasswordComponent.prototype.ngOnInit = function () {
        this.requestResetPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    };
    RequestResetPasswordComponent.prototype.requestResetPassword = function () {
        var _this = this;
        if (this.requestResetPasswordForm.invalid || this.submitted) {
            return;
        }
        this.submitted = true;
        this.userRepository
            .requestResetPassword(this.requestResetPasswordForm.get('email').value)
            .pipe(finalize(function () {
            _this.submitted = false;
        }))
            .subscribe(function (_a) {
            var message = _a.message;
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var alert;
                var _this = this;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.alertController.create({
                                header: 'Réinitialisation du mot de passe',
                                message: message,
                                buttons: [
                                    {
                                        text: 'J\'ai compris',
                                        handler: function () {
                                            _this.router.navigate(['login']);
                                        }
                                    }
                                ]
                            })];
                        case 1:
                            alert = _b.sent();
                            return [4 /*yield*/, alert.present()];
                        case 2:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    return RequestResetPasswordComponent;
}());
export { RequestResetPasswordComponent };
