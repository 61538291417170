import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { UserRepository } from '../../../repositories/user.repository';

@Component({
  selector: 'app-request-reset-password',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss']
})
export class RequestResetPasswordComponent implements OnInit {
  requestResetPasswordForm: FormGroup;
  submitted = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly userRepository: UserRepository,
    private readonly alertController: AlertController,
    private readonly router: Router) {}

  ngOnInit(): void {
    this.requestResetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]

    });
  }

  requestResetPassword(): void {
    if (this.requestResetPasswordForm.invalid || this.submitted) {
      return;
    }

    this.submitted = true;
    this.userRepository
      .requestResetPassword(this.requestResetPasswordForm.get('email').value)
      .pipe(
        finalize(() => {
          this.submitted = false;
        })
      )
      .subscribe(async ({message}) => {

        const alert = await this.alertController.create({
          header: 'Réinitialisation du mot de passe',
          message,
          buttons: [
            {
              text: 'J\'ai compris',
              handler: () => {
                this.router.navigate(['login']);
              }
            }
          ]
        });
        await alert.present();
      });
  }
}
