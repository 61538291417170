import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity';
import { Benefit } from '../models/benefit';
import { ConstantInterface } from '../models/constants';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class BenefitsRepository extends AbstractRepository<Benefit, ConstantInterface> {
    constructor(protected readonly http: HttpClient) {
        super(http);
    }

    getModelName(): string {
        return 'benefits';
    }

    findByCode(code: string): Observable<Benefit> {
        return this.http.get<Benefit>([this.baseUrl, this.getModelName(), 'by-code', code].join('/'));
    }

    getActivityBenefitActivities(code: string): Observable<Array<Activity>> {
        return this.http.get<Array<Activity>>([this.baseUrl, this.getModelName(), code, 'activities'].join('/'));
    }
}
