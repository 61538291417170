import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// add Google Analytics script to <head>
var script = document.createElement('script');
script.innerHTML = "    (function(i, s, o, g, r, a, m) {\n      i['GoogleAnalyticsObject'] = r;\n      i[r] = i[r] || function() {\n        (i[r].q = i[r].q || []).push(arguments)\n      }, i[r].l = 1 * new Date();\n      a = s.createElement(o),\n              m = s.getElementsByTagName(o)[0];\n      a.async = 1;\n      a.src = g;\n      m.parentNode.insertBefore(a, m)\n    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');\n    ga('create', '" + environment.googleAnalytics + "', 'auto');";
if (environment.googleAnalytics) {
    document.head.appendChild(script);
}
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) {
    // tslint:disable-next-line:no-console
    console.log(err);
});
