import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var TeamGroupRepository = /** @class */ (function (_super) {
    tslib_1.__extends(TeamGroupRepository, _super);
    function TeamGroupRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    TeamGroupRepository.prototype.getModelName = function () {
        return 'team-groups';
    };
    TeamGroupRepository.prototype.getTeamGroup = function () {
        return this.http.get([this.baseUrl, this.getModelName()].join('/'));
    };
    return TeamGroupRepository;
}(AbstractRepository));
export { TeamGroupRepository };
