<form [formGroup]="contributionForm">
  <input formControlName="contribution" [(ngModel)]="amount" type="hidden" required />
</form>

<div fxLayout="column" fxLayoutGap="10px">
  <div>
    <ion-button
        *ngFor="let quickAmount of quickAmounts"
        (click)="onQuickAmountClick($event, quickAmount)"
        [fill]="isQuickAmountChecked(quickAmount) ? 'solid' : 'outline'"
        color="primary"
    >
      {{ quickAmount }}&nbsp;<small>€/m</small>
    </ion-button>
  </div>

  <div>
    <ion-item [color]="isOtherAmountInputChecked(otherAmount) ? 'primary' : undefined">
      <ion-input
          #contributionInput
          slot="end"
          type="text"
          min="1"
          step="1"
          required
          placeholder="xx €/M"
          [(ngModel)]="otherAmount"
          (focus)="otherAmountFocused = true"
          (blur)="otherAmountFocused = false"
      ></ion-input>
      <ion-label>Ou montant libre</ion-label>
    </ion-item>

    <app-form-error
        [field]="contributionForm.get('contribution')"
        [messages]="{'required': 'Veuillez saisir le montant de la contribution mensuelle'}"
    ></app-form-error>
  </div>
</div>
