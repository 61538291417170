import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var UserActivityHistoryRepository = /** @class */ (function (_super) {
    tslib_1.__extends(UserActivityHistoryRepository, _super);
    function UserActivityHistoryRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    UserActivityHistoryRepository.prototype.getModelName = function () {
        return 'user-activity-histories';
    };
    UserActivityHistoryRepository.prototype.searchHistory = function (startDate, endDate) {
        var params = new HttpParams({
            fromObject: {
                startDate: startDate,
                endDate: endDate
            }
        });
        return this.http.get([this.baseUrl, 'users', 'me', this.getModelName()].join('/'), { params: params });
    };
    UserActivityHistoryRepository.prototype.getLastHistoryActivities = function () {
        return this.http.get([this.baseUrl, 'users', 'me', 'last-history-activities'].join('/'));
    };
    UserActivityHistoryRepository.prototype.addUserHistoryActivity = function (activityId, userContextStepId) {
        var params = [this.baseUrl, 'users', 'me', this.getModelName(), 'activity', activityId];
        if (userContextStepId) {
            params.push(userContextStepId);
        }
        return this.http.post(params.join('/'), {});
    };
    UserActivityHistoryRepository.prototype.markActivityHistoryAsForgotten = function (userActivityHistoryId) {
        return this.http.post([this.baseUrl, 'users', 'me', this.getModelName(), userActivityHistoryId, 'forget'].join('/'), {});
    };
    UserActivityHistoryRepository.prototype.getUnforgottenActivityHistory = function (activityId) {
        return this.http.get([this.baseUrl, 'users', 'me', this.getModelName(), 'activity', activityId].join('/'));
    };
    return UserActivityHistoryRepository;
}(AbstractRepository));
export { UserActivityHistoryRepository };
