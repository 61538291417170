import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';

import { ActivatedRoute } from '@angular/router';
import { JWTToken, UserInterface } from '../../../models/jwt-token';

@Injectable()
export class TokenService {

  private readonly tokenPrefix = 'mycharlotte';
  private readonly storageTokenKey = 'token';
  private readonly storageRefreshTokenKey = 'refreshToken';

  private token: JWTToken;
  private readonly jwtHelper = new JwtHelperService();

  constructor(
    private readonly storage: Storage,
    private readonly route: ActivatedRoute
  ) {
    this.loadStoredTokens();
  }

  async loadQueryParamsTokens(): Promise<void> {
    const token = this.route.snapshot.queryParamMap.get(this.storageTokenKey);
    const refreshToken = this.route.snapshot.queryParamMap.get(this.storageRefreshTokenKey);
    // tslint:disable-next-line:binary-expression-operand-order
    if (null !== token && null !== refreshToken) {
      await this.loadToken(token, refreshToken);
    }
  }

  async loadStoredTokens(): Promise<void> {
    const savedToken = await this.storage.get(`${this.tokenPrefix}_${this.storageTokenKey}`);
    const savedRefreshToken = await this.storage.get(`${this.tokenPrefix}_${this.storageRefreshTokenKey}`);
    await this.loadToken(savedToken, savedRefreshToken);
  }

  isSignedIn(): boolean {
    return this.token !== null && this.token.expirationDate > new Date();
  }

  getToken(): JWTToken {
    return this.token;
  }

  getCurrentUser(): UserInterface {
    return this.token.decodedToken;
  }

  async loadToken(encodedToken: string, refreshToken: string): Promise<void> {
    if (!encodedToken) {
      return undefined;
    }

    let decodedToken;
    try {
      decodedToken = this.jwtHelper.decodeToken(encodedToken);
    } catch (e) {
      console.warn('cannot decode jwtToken');
      await this.cleanStorage();

      return undefined;
    }

    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);

    this.setToken({
      encodedToken,
      decodedToken,
      expirationDate,
      refreshToken
    });
    await this.storage.set(`${this.tokenPrefix}_${this.storageTokenKey}`, encodedToken);
    await this.storage.set(`${this.tokenPrefix}_${this.storageRefreshTokenKey}`, refreshToken);
  }

  async cleanStorage(): Promise<void> {
    this.setToken(undefined);
    await this.storage.remove(`${this.tokenPrefix}_${this.storageTokenKey}`);
    await this.storage.remove(`${this.tokenPrefix}_${this.storageRefreshTokenKey}`);
  }

  private setToken(token: JWTToken): void {
    this.token = token;
  }
}
