import * as tslib_1 from "tslib";
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';
import { ActivatedRoute } from '@angular/router';
var TokenService = /** @class */ (function () {
    function TokenService(storage, route) {
        this.storage = storage;
        this.route = route;
        this.tokenPrefix = 'mycharlotte';
        this.storageTokenKey = 'token';
        this.storageRefreshTokenKey = 'refreshToken';
        this.jwtHelper = new JwtHelperService();
        this.loadStoredTokens();
    }
    TokenService.prototype.loadQueryParamsTokens = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, refreshToken;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        token = this.route.snapshot.queryParamMap.get(this.storageTokenKey);
                        refreshToken = this.route.snapshot.queryParamMap.get(this.storageRefreshTokenKey);
                        if (!(null !== token && null !== refreshToken)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadToken(token, refreshToken)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TokenService.prototype.loadStoredTokens = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var savedToken, savedRefreshToken;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get(this.tokenPrefix + "_" + this.storageTokenKey)];
                    case 1:
                        savedToken = _a.sent();
                        return [4 /*yield*/, this.storage.get(this.tokenPrefix + "_" + this.storageRefreshTokenKey)];
                    case 2:
                        savedRefreshToken = _a.sent();
                        return [4 /*yield*/, this.loadToken(savedToken, savedRefreshToken)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TokenService.prototype.isSignedIn = function () {
        return this.token !== null && this.token.expirationDate > new Date();
    };
    TokenService.prototype.getToken = function () {
        return this.token;
    };
    TokenService.prototype.getCurrentUser = function () {
        return this.token.decodedToken;
    };
    TokenService.prototype.loadToken = function (encodedToken, refreshToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var decodedToken, e_1, expirationDate;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!encodedToken) {
                            return [2 /*return*/, undefined];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 2, , 4]);
                        decodedToken = this.jwtHelper.decodeToken(encodedToken);
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.warn('cannot decode jwtToken');
                        return [4 /*yield*/, this.cleanStorage()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, undefined];
                    case 4:
                        expirationDate = new Date(0);
                        expirationDate.setUTCSeconds(decodedToken.exp);
                        this.setToken({
                            encodedToken: encodedToken,
                            decodedToken: decodedToken,
                            expirationDate: expirationDate,
                            refreshToken: refreshToken
                        });
                        return [4 /*yield*/, this.storage.set(this.tokenPrefix + "_" + this.storageTokenKey, encodedToken)];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.storage.set(this.tokenPrefix + "_" + this.storageRefreshTokenKey, refreshToken)];
                    case 6:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TokenService.prototype.cleanStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setToken(undefined);
                        return [4 /*yield*/, this.storage.remove(this.tokenPrefix + "_" + this.storageTokenKey)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.storage.remove(this.tokenPrefix + "_" + this.storageRefreshTokenKey)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TokenService.prototype.setToken = function (token) {
        this.token = token;
    };
    return TokenService;
}());
export { TokenService };
