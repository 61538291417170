import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StripeRepository } from '../../../repositories/stripe.repository';
import { UserRepository } from '../../../repositories/user.repository';
import { AuthenticationService } from '../authentication/authentication.service';
import { TokenService } from '../authentication/token.service';
var PaymentComponent = /** @class */ (function () {
    function PaymentComponent(formBuilder, alertController, route, authenticationService, stripeRepository, userRepository, router, loadingController, modalController, tokenService) {
        this.formBuilder = formBuilder;
        this.alertController = alertController;
        this.route = route;
        this.authenticationService = authenticationService;
        this.stripeRepository = stripeRepository;
        this.userRepository = userRepository;
        this.router = router;
        this.loadingController = loadingController;
        this.modalController = modalController;
        this.tokenService = tokenService;
        this.submitted = false;
        this.paymentCancelled = false;
        this.contribution = 10;
    }
    PaymentComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var contribution, status;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        contribution = +this.route.snapshot.queryParamMap.get('contribution') || this.contribution;
                        this.contributionForm = this.formBuilder.group({
                            contribution: [contribution, [Validators.required, Validators.min(1)]]
                        });
                        this.contributionForm.get('contribution')
                            .valueChanges
                            .subscribe(function (newContribution) {
                            _this.contribution = newContribution;
                            _this.generateStripeSessionId(newContribution);
                        });
                        status = this.route.snapshot.queryParamMap.get('status');
                        if (!(status === 'cancel')) return [3 /*break*/, 1];
                        this.paymentCancelled = true;
                        return [3 /*break*/, 3];
                    case 1:
                        if (!(status === 'success')) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.checkPayment()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PaymentComponent.prototype.ionViewWillEnter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var status;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        status = this.route.snapshot.queryParamMap.get('status');
                        if (!(status !== 'checked')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.checkPayment()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.generateStripeSessionId(this.contribution);
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentComponent.prototype.processPayment = function () {
        window.Stripe(environment.stripePublicKey)
            .redirectToCheckout({
            sessionId: this.stripeSessionId
        })
            .then(function (result) {
            console.error(result);
        });
    };
    PaymentComponent.prototype.skipPaymentRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.submitted = true;
                        return [4 /*yield*/, this.loadingController.create({
                                message: 'Veuillez patienter...',
                                translucent: true,
                                showBackdrop: true
                            })];
                    case 1:
                        loading = _a.sent();
                        loading.present();
                        this.userRepository.skipPaymentRequest()
                            .pipe(map(function () {
                            _this.refreshTokenWithPaymentInfo(loading);
                        }, function () {
                            loading.dismiss();
                            _this.alertController.create({
                                header: 'Impossible de vérifier votre paiement',
                                message: 'Une erreur s\'est produite, veuillez réessayer.',
                                buttons: [
                                    {
                                        text: 'J\'ai compris',
                                        handler: function () {
                                            _this.router.navigate(['payment']);
                                        }
                                    }
                                ]
                            })
                                .then(function (a) { return a.present(); });
                        }))
                            .subscribe();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentComponent.prototype.generateStripeSessionId = function (contribution) {
        var _this = this;
        this.stripeSessionId = undefined;
        this.stripeRepository
            .generateStripeSessionId(this.tokenService.getToken().refreshToken, contribution)
            .subscribe(function (_a) {
            var stripeSessionId = _a.stripeSessionId;
            _this.stripeSessionId = stripeSessionId;
        });
    };
    PaymentComponent.prototype.checkPayment = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loading, sessionId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadingController.create({
                            message: 'Veuillez patienter...',
                            translucent: true,
                            showBackdrop: true
                        })];
                    case 1:
                        loading = _a.sent();
                        loading.present();
                        sessionId = this.route.snapshot.queryParamMap.get('sessionId');
                        if (sessionId) {
                            this.stripeRepository.attachSubscription(sessionId)
                                .pipe(map(function () {
                                _this.refreshTokenWithPaymentInfo(loading);
                            }, function () {
                                loading.dismiss();
                                _this.alertController.create({
                                    header: 'Impossible de vérifier votre paiement',
                                    message: 'Une erreur s\'est produite de la vérification de votre paiement. Merci de nous contacter.',
                                    buttons: [
                                        {
                                            text: 'J\'ai compris',
                                            handler: function () {
                                                _this.router.navigate(['payment']);
                                            }
                                        }
                                    ]
                                })
                                    .then(function (a) { return a.present(); });
                            }))
                                .subscribe();
                        }
                        else {
                            this.refreshTokenWithPaymentInfo(loading);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentComponent.prototype.refreshTokenWithPaymentInfo = function (loading) {
        this.authenticationService.refreshToken(true)
            .pipe(map(function () {
            setTimeout(function () {
                loading.dismiss();
                // Force guard
                window.location = window.location.href.split('?')[0] + "?status=checked";
            }, 1000);
        }))
            .subscribe();
    };
    return PaymentComponent;
}());
export { PaymentComponent };
