import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Treatment } from '../models/treatment';
import { TreatmentsRepository } from '../repositories/treatments.repository';

@Injectable()
export class TreatmentResolver implements Resolve<Treatment> {
  constructor(
    private readonly router: Router,
    private readonly toastController: ToastController,
    private readonly treatmentsRepository: TreatmentsRepository
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Treatment> {
    const id = +route.paramMap.get('treatmentId');

    return this.treatmentsRepository.findById(id)
      .pipe(
        catchError(() => this.handleError())
      );
  }

  private handleError(): Observable<Treatment> {
    this.toastController.create({
      message: 'Impossible de récupérer le traitement',
      duration: 2000
    })
      .then(t => t.present());
    this.router.navigate(['..']);

    return of();
  }
}
