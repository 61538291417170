import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { createTextMaskInputElement } from 'text-mask-core/dist/textMaskCore';
import {applyMixins} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-amount-selector',
  templateUrl: './amount-selector.component.html',
  styleUrls: ['./amount-selector.component.scss']
})
export class AmountSelectorComponent implements OnInit {
  static readonly DEFAULT_AMOUNT = 10;
  static readonly QUICK_AMOUNTS = [5, 10, 25, 50];

  amount: number;
  otherAmount: number;
  quickAmounts: Array<number>;
  otherAmountFocused: boolean;

  @ViewChild('contributionInput', { static: true }) contributionInput: IonInput;

  @Input() contributionForm: FormGroup;
  private onDestroy$ = new Subject<void>();

  private static convertToIntegerAmount(amount): number {
    return Math.max(
      +amount.split(' ')
      .shift(),
      0
    );
  }

  ngOnInit(): void {
    const amount = +this.contributionForm.get('contribution').value;
    if (AmountSelectorComponent.QUICK_AMOUNTS.includes(amount)) {
      this.otherAmountFocused = false;
    } else {
      this.otherAmountFocused = true;
      this.otherAmount = amount;
    }
    this.amount = amount;
    this.quickAmounts = AmountSelectorComponent.QUICK_AMOUNTS;
    this.updateAmounts();
    this.configureInput();
  }

  onQuickAmountClick($event, amount): void {
    this.amount = +amount;
    this.otherAmount = undefined;
    this.updateAmounts();
  }

  isQuickAmountChecked(quickAmount): boolean {
    return (!this.otherAmountFocused || (this.otherAmountFocused && !this.otherAmount)) && !this.otherAmount && (this.amount === quickAmount);
  }

  isOtherAmountInputChecked(otherAmount): any {
    return otherAmount;
  }

  updateAmounts(): void {
    this.contributionForm.patchValue({amount: Math.max(this.amount, 0)});
  }

  async configureInput(): Promise<void> {
    const input = await this.contributionInput.getInputElement();
    const maskedInput = createTextMaskInputElement({
      inputElement: input,
      mask: createNumberMask({
        prefix: '',
        suffix: ' €/M'
      })
    });
    this.contributionInput.ionChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: CustomEvent) => {
        const { value } = event.detail;
        maskedInput.update(value);
        this.contributionInput.value = input.value;

        if (value) {
          this.amount = AmountSelectorComponent.convertToIntegerAmount(value);
          this.updateAmounts();
        }
      });
    input.focus();
  }
}
