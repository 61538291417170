import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity';
import { ActivitiesConstants } from '../models/constants';
import { AbstractRepository } from './abstract.repository';

@Injectable()

export class ActivitiesRepository extends AbstractRepository<Activity, ActivitiesConstants> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  getModelName(): string {
    return 'activities';
  }

  getSuggestions(id: number): Observable<Array<Activity>> {
    return this.http.get<Array<Activity>>([this.baseUrl, this.getModelName(), id, 'suggestions'].join('/'));
  }

  getTutorials(id: number): Observable<Array<Activity>> {
    return this.http.get<Array<Activity>>([this.baseUrl, this.getModelName(), id, 'tutorials'].join('/'));
  }

  logView(id: number): Observable<any> {
    return this.http.post<any>([this.baseUrl, this.getModelName(), id, 'log'].join('/'), {});
  }

  getActivitiesByLength(length: string): Observable<Array<Activity>> {
    const filter = {
      duration: length
    };
    const params = new HttpParams({
            fromObject: filter as { [param: string]: string }
          });

    return this.http.get<Array<Activity>>([this.baseUrl, this.getModelName()].join('/'), {params});
  }

}
