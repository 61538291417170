import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantInterface } from '../models/constants';
import { Library } from '../models/library';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class LibraryRepository extends AbstractRepository<Library, ConstantInterface> {
    constructor(protected readonly http: HttpClient) {
        super(http);
    }

    getModelName(): string {
        return 'library';
    }
}
