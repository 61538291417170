import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { UsersConstants } from '../../../models/constants';
import { UserRepository } from '../../../repositories/user.repository';
import { setFormErrors } from '../form-error/form.mapper';
import { CGUPage } from './cgu/cgu.page';
import { ConfidentialityAgreementComponent } from './confidentiality-agreement/confidentiality-agreement.component';

const repeatPasswordValidator = (group: FormGroup) => {
  const password = group.get('plainPassword').value;
  const passwordConfirmation = group.get('confirmPassword').value;

  return password === passwordConfirmation ? undefined : {passwordsNotEqual: true};
};

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  formError = false;
  registerForm: FormGroup;
  submitted = false;
  constants: UsersConstants;
  loading = true;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly userRepository: UserRepository,
    private readonly modalController: ModalController,
    private readonly alertController: AlertController,
    private readonly router: Router) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: { constants: UsersConstants }) => {
      this.constants = data.constants;
      this.registerForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        plainPassword: ['', [Validators.required, Validators.minLength(4)]],
        confirmPassword: ['', Validators.required],
        type: ['', Validators.required],
        healthInstitution: [''],
        otherHealthInstitution: [''],
        newsletter: [false],
        renunciation: [false, Validators.requiredTrue]
      }, {
        validator: repeatPasswordValidator
      });
      this.loading = false;
    });
  }

  async openCGU(): Promise<void> {
    const modal = await this.modalController.create({
      component: CGUPage,
      componentProps: {
        contentCGU: this.constants.cgu
      }
    });
    modal.present();
  }

  async openConfidentialityAgreement(): Promise<void> {
    const modal = await this.modalController.create({
      component: ConfidentialityAgreementComponent,
      componentProps: {
        contentCA: this.constants.confidentialityAgreement
      }
    });
    modal.present();
  }

  register(): void {
    if (this.registerForm.invalid || this.submitted) {
      return;
    }

    this.userRepository.create({
      // tslint:disable-next-line:newline-per-chained-call
      username: this.registerForm.get('email').value,
      email: this.registerForm.get('email').value,
      // tslint:disable-next-line:newline-per-chained-call
      firstName: this.registerForm.get('firstName').value,
      // tslint:disable-next-line:newline-per-chained-call
      lastName: this.registerForm.get('lastName').value,
      // tslint:disable-next-line:newline-per-chained-call
      newsletter: this.registerForm.get('newsletter').value,
      // tslint:disable-next-line:newline-per-chained-call
      plainPassword: this.registerForm.get('plainPassword').value,
      type: this.registerForm.get('type').value,
      healthInstitution: this.registerForm.get('healthInstitution').value,
      otherHealthInstitution: this.registerForm.get('otherHealthInstitution').value
    })
      .subscribe(
        () => {
          this.formError = false;
          this.submitted = false;
          this.alertController.create({
            header: 'Validez votre email',
            // tslint:disable-next-line:max-line-length
            message: 'Vous allez recevoir un email dans les prochaines secondes, merci de cliquer sur le lien dans l\'email pour valider votre inscription.',
            buttons: ['OK']
          })
            .then(t => t.present());
          this.router.navigate(['login']);

        }, err => {
          const { status } = err;
          if (status === 422) {
            setFormErrors(this.registerForm, err.error.form);
          }
          this.formError = true;
          this.submitted = false;

          return err;
        }
      )
    ;
  }
}
