import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import * as d3 from 'd3';
import moment from 'moment';
import RangeTouch from 'rangetouch';
import { ConfirmRegistrationComponent } from './core/confirm-registration/confirm-registration.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { ManUpService } from './core/utils/manup/manup.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(platform, splashScreen, statusBar, angulartics2GoogleAnalytics, deeplinks, router, zone, manup) {
        var _this = this;
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.angulartics2GoogleAnalytics = angulartics2GoogleAnalytics;
        this.deeplinks = deeplinks;
        this.router = router;
        this.zone = zone;
        this.manup = manup;
        this.initializeApp();
        angulartics2GoogleAnalytics.startTracking();
        moment.locale('fr');
        d3.timeFormatDefaultLocale({
            dateTime: '%A, le %e %B %Y, %X',
            date: '%d/%m/%Y',
            time: '%H:%M:%S',
            periods: ['AM', 'PM'],
            days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
            shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
            months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
            shortMonths: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']
        });
        // tslint:disable-next-line:no-unused-expression
        new RangeTouch('input[type="range"]');
        this.deeplinks.route({
            '/confirm-registration': ConfirmRegistrationComponent,
            '/reset-password': ResetPasswordComponent
        })
            .subscribe(function (match) {
            _this.zone.run(function () {
                _this.router.navigate([match.$link.path], { queryParams: match.$args });
            });
        });
    }
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        this.platform.ready()
            .then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.statusBar.styleDefault();
                this.splashScreen.hide();
                this.manup.validate()
                    .then(function () {
                });
                return [2 /*return*/];
            });
        }); });
    };
    return AppComponent;
}());
export { AppComponent };
