import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
var FormErrorComponent = /** @class */ (function () {
    function FormErrorComponent() {
        this.defaultMessages = {
            required: 'Ce champ est requis.',
            forceYes: 'Vous devez répondre "Oui" pour continuer'
        };
    }
    FormErrorComponent.prototype.ngOnInit = function () {
        this.errorMessages = tslib_1.__assign({}, this.defaultMessages, this.messages);
    };
    return FormErrorComponent;
}());
export { FormErrorComponent };
