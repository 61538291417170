import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';

import { RouteReuseStrategy } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { Angulartics2Module } from 'angulartics2';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ManUpModule } from './core/utils/manup/manup.module';
import { SentryErrorHandler } from './core/utils/sentry.service';

export const getErrorHandler = (): ErrorHandler => {
  if (environment.production) {
    return new SentryErrorHandler();
  }

  return new ErrorHandler();
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    CoreModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'mycharlotte-storage',
      driverOrder: ['localstorage', 'indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    Angulartics2Module.forRoot(),
    NgxStripeModule.forRoot(environment.stripePublicKey),
    ManUpModule.forRoot({ url: environment.versionsUrl })
  ],
  providers: [
    SplashScreen,
    StatusBar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useFactory: getErrorHandler },
    InAppBrowser,
    Deeplinks,
    AppVersion,
    Insomnia
],
  bootstrap: [AppComponent]
})
export class AppModule {}
