import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserContext } from '../models/user-context';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class UserContextRepository extends AbstractRepository<UserContext> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  getAvailableContexts(): Observable<Array<UserContext>> {
    return this.http.get<Array<UserContext>>([this.baseUrl, 'contexts', 'available'].join('/'));
  }

  setCurrentActiveContext(id: number): Observable<UserContext> {
    return this.http.post<UserContext>([this.baseUrl, 'contexts', id, 'active'].join('/'), {});
  }

  getModelName(): string {
    return 'user-context';
  }
}
