import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.page.html',
  styleUrls: ['./cgu.page.scss']
})
export class CGUPage implements OnInit {
  @Input() contentCGU: string;
  contentCGUHTML: SafeHtml;

  constructor(private readonly modalController: ModalController, private readonly domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.contentCGUHTML = this.domSanitizer.bypassSecurityTrustHtml(this.contentCGU);
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
