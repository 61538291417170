import { FormGroup, ValidationErrors } from '@angular/forms';
import { forEach, isEmpty, reduce } from 'lodash';

export const formatFieldErrors = (errors: Array<string>): any =>
  reduce(errors, (acc, error) => {
    acc[error] = true;

    return acc;
  }, {});

export const setFormErrors = (form: FormGroup, formErrors: ValidationErrors): void => {
  // 1 - Reset errors
  form.setErrors(undefined);
  forEach(form.controls, field => {
    field.setErrors(undefined);
  });

  // 2 - Set errors
  forEach(formErrors.children, (errors, field) => {
    if (form.get(field)) {
      const formattedError = formatFieldErrors(errors);
      if (!isEmpty(formattedError)) {
        form.get(field)
          .setErrors(formattedError);
      }
    }
  });
};
