import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TokenService } from './token.service';
var AuthenticationGuardService = /** @class */ (function () {
    function AuthenticationGuardService(tokenService, router, toastController) {
        this.tokenService = tokenService;
        this.router = router;
        this.toastController = toastController;
    }
    AuthenticationGuardService.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var token, subscriptionExpired, nextPaymentRequestExpired;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.tokenService.getToken()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.tokenService.loadStoredTokens()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.tokenService.loadQueryParamsTokens()];
                    case 3:
                        _a.sent();
                        token = this.tokenService.getToken();
                        if (!token) {
                            this.toastController.create({
                                message: 'Veuillez vous reconnecter pour accéder à cette page.',
                                duration: 2000
                            })
                                .then(function (t) { return t.present(); });
                            this.router.navigate(['login']);
                            return [2 /*return*/, false];
                        }
                        subscriptionExpired = token.decodedToken.subscription === undefined || token.decodedToken.subscription * 1000 < (new Date()).getTime();
                        nextPaymentRequestExpired = token.decodedToken.nextPaymentRequest === undefined ||
                            token.decodedToken.nextPaymentRequest * 1000 < (new Date()).getTime();
                        if (state.url.startsWith('/payment')) {
                            if (subscriptionExpired && nextPaymentRequestExpired) {
                                return [2 /*return*/, true];
                            }
                            this.router.navigate(['menu', 'programme']);
                            return [2 /*return*/, false];
                        }
                        if (subscriptionExpired && nextPaymentRequestExpired) {
                            this.router.navigate(['/payment']);
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/, true];
                }
            });
        });
    };
    return AuthenticationGuardService;
}());
export { AuthenticationGuardService };
