import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity';
import { Category } from '../models/category';
import { ConstantInterface } from '../models/constants';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class CategoriesRepository extends AbstractRepository<Category, ConstantInterface> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  getModelName(): string {
    return 'categories';
  }

  getInLibraryCategories(): Observable<Array<Category>> {
    return this.http.get<Array<Category>>([this.baseUrl, this.getModelName(), 'in-library'].join('/'));
  }

  findByCode(code: string): Observable<Category> {
    return this.http.get<Category>([this.baseUrl, this.getModelName(), 'by-code', code].join('/'));
  }

  getActivityCategoryActivities(code: string): Observable<Array<Activity>> {
    return this.http.get<Array<Activity>>([this.baseUrl, this.getModelName(), code, 'activities'].join('/'));
  }
}
