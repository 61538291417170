import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToastController } from '@ionic/angular';
import { DecodedToken } from '../../../models/jwt-token';
import { UserRepository } from '../../../repositories/user.repository';
import { TokenService } from './token.service';

@Injectable()
export class AuthenticationService {

  constructor(
    private readonly userRepository: UserRepository,
    private readonly tokenService: TokenService,
    private readonly toastController: ToastController
  ) {}

  login(username: string, password: string): Observable<Promise<DecodedToken>> {
    return this.userRepository.login(username, password)
    .pipe(
      map(
      async ({token, refresh_token}) => {
        await this.tokenService.loadToken(token, refresh_token);
        const {decodedToken} = this.tokenService.getToken();
        this.toastController.create({
            message: `Vous êtes désormais connectée en tant que ${decodedToken.email}`,
            duration: 2000
          })
            .then(t => t.present());

        return decodedToken;
      }, error => {
        console.error(error);

        return error;
      }
    ));
  }

  refreshToken(quite = false): Observable<Promise<DecodedToken>> {
    const currentToken = this.tokenService.getToken();

    return this.userRepository.refreshToken(currentToken.refreshToken)
      .pipe(
        map(async ({token, refresh_token}) => {
          await this.tokenService.loadToken(token, refresh_token);
          const {decodedToken} = this.tokenService.getToken();
          if (!quite) {
            this.toastController.create({
              message: `Vous êtes de nouveau connecté·e en tant que ${decodedToken.email}`,
              duration: 2000
            })
              .then(t => t.present());
          }

          return decodedToken;
        }));
  }

  async logout(message = 'Vous avez bien été déconnectée'): Promise<void> {
    await this.tokenService.cleanStorage();
    this.toastController.create({
      message ,
      duration: 2000
    })
    .then(t => t.present());
  }
}
