import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SubscriptionInfo } from '../models/subscription-info';
import { UserRepository } from '../repositories/user.repository';

@Injectable()
export class UsersSubscriptionResolver implements Resolve<SubscriptionInfo | null> {
  constructor(
    private readonly router: Router,
    private readonly toastController: ToastController,
    private readonly userRepository: UserRepository
  ) {}

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<SubscriptionInfo | null> {
    return this.userRepository.getSubscriptionInfo()
      .pipe(
        catchError(() =>  of(undefined))
      );
  }
}
