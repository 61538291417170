import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserActivityHistory, UserActivityHistoryExcerpt } from '../models/user-activity-history';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class UserActivityHistoryRepository extends AbstractRepository<UserActivityHistory> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  getModelName(): string {
    return 'user-activity-histories';
  }

  searchHistory(startDate: string, endDate: string): Observable<Array<UserActivityHistory>> {
    const params = new HttpParams({
      fromObject: {
        startDate,
        endDate
      }
    });

    return this.http.get<Array<UserActivityHistory>>([this.baseUrl, 'users', 'me', this.getModelName()].join('/'), {params});
  }

  getLastHistoryActivities(): Observable<Array<UserActivityHistoryExcerpt>> {
    return this.http.get<Array<UserActivityHistoryExcerpt>>([this.baseUrl, 'users', 'me', 'last-history-activities'].join('/'));
  }

  addUserHistoryActivity(activityId: number, userContextStepId?: number): Observable<UserActivityHistory> {
    const params = [this.baseUrl, 'users', 'me', this.getModelName(), 'activity', activityId];
    if (userContextStepId) {
      params.push(userContextStepId);
    }

    return this.http.post<UserActivityHistory>(params.join('/'), {});
  }

  markActivityHistoryAsForgotten(userActivityHistoryId: number): Observable<UserActivityHistory> {
    return this.http.post<UserActivityHistory>(
      [this.baseUrl, 'users', 'me', this.getModelName(), userActivityHistoryId, 'forget'].join('/'),
      {}
      );
  }

  getUnforgottenActivityHistory(activityId: number): Observable<UserActivityHistory> {
    return this.http.get<UserActivityHistory>(
      [this.baseUrl, 'users', 'me', this.getModelName(), 'activity', activityId].join('/'));
  }
}
