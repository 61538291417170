<ion-content scrollY="true">
  <div class="payment-wrapper" fxLayoutAlign="center center">
    <div fxLayoutAlign.gt-xs="center center" fxFlexFill>
      <ion-card class="payment">
        <div class="payment-header">
          Contribuer à myCharlotte
        </div>
        <ion-card-content class="payment-content">
          <img class="payment-icon" src="assets/images/logo.png" alt="myCharlotte" />

          <ion-label>
            <ion-item *ngIf="paymentCancelled" color="danger">
              <ion-text>
                Vous avez annulez le paiement, veuillez réessayer.
              </ion-text>
            </ion-item>

            <ion-card>
              <ion-card-header>
                <ion-card-title>
                  L’accès à myCharlotte est gratuit.
                </ion-card-title>
              </ion-card-header>

              <ion-card-content>
                <p>
                  Nous avons décidé de vous donner le choix de contribuer à myCharlotte selon vos possibilités.
                </p>

                <p>
                  Afin de maintenir l’appli de façon pérenne, de pouvoir enrichir le contenu et développer de nouvelles fonctionnalités, nous avons besoin de votre soutien.
                  Les petits ruisseaux font des applis utiles.
                </p>

              </ion-card-content>

              <ion-card-content>
                <div class="amount-selector">
                  <app-amount-selector
                      *ngIf="contributionForm"
                      [contributionForm]="contributionForm"
                  ></app-amount-selector>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" class="payment-button">
                  <ion-button
                      color="primary"
                      type="button"
                      (click)="processPayment()"
                      [disabled]="!stripeSessionId"
                  >
                    <span *ngIf="!stripeSessionId && contribution">Veuillez patienter</span>
                    <span *ngIf="!stripeSessionId && !contribution">Montant invalide</span>
                    <ion-spinner *ngIf="!stripeSessionId && contribution"></ion-spinner>
                    <span *ngIf="stripeSessionId">Contribuer pour {{ contribution }}€/mois</span>
                  </ion-button>
                </div>

                <p>
                  Vous pouvez arrêter votre contribution mensuelle à tout moment.
                </p>
              </ion-card-content>
            </ion-card>
          </ion-label>

          <hr class="payment-form-divider" />
          <ion-label>
            <h2>Vous souhaitez ne pas contribuer pour l'instant&nbsp;?</h2>
          </ion-label>
          <div fxLayout="row" fxLayoutAlign="center center" class="payment-button">
            <ion-button
                type="button"
                fill="outline"
                color="primary"
                (click)="skipPaymentRequest()"
            >
              Ne pas contribuer
            </ion-button>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </div>
</ion-content>
