import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity';
import { UsersConstants } from '../models/constants';
import { DailySurvey } from '../models/daily-survey';
import { SubscriptionInfo } from '../models/subscription-info';
import { Treatment } from '../models/treatment';
import { User, UserProfile } from '../models/user';
import { UserContext } from '../models/user-context';
import { AbstractRepository, MessageInterface } from './abstract.repository';

export interface TokenResponseInterface {
  token: string;
  refresh_token: string;
}

@Injectable()
export class UserRepository extends AbstractRepository<User, UsersConstants> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  login(username: string, password: string): Observable<TokenResponseInterface> {
    return this.http.post<TokenResponseInterface>([this.baseUrl, 'login_check'].join('/'), {
      username,
      password
    });
  }

  refreshToken(refreshToken: string): Observable<TokenResponseInterface> {
    return this.http.post<TokenResponseInterface>([this.baseUrl, 'token', 'refresh'].join('/'), {
      refresh_token: refreshToken
    });
  }

  requestResetPassword(username: string): Observable<MessageInterface> {
    return this.http.post<MessageInterface>([this.baseUrl, this.getModelName(), 'request-reset-password'].join('/'), {
      username
    });
  }

  resetPassword(token: string, plainPassword: string): Observable<MessageInterface> {
    return this.http.post<MessageInterface>([this.baseUrl, this.getModelName(), 'reset-password', token].join('/'), {
      plainPassword
    });
  }

  enableUser(token: string): Observable<MessageInterface | User> {
    return this.http.post<MessageInterface | User>([this.baseUrl, this.getModelName(), 'enable-user', token].join('/'), {});
  }

  changePassword(plainPassword: string): Observable<MessageInterface> {
    return this.http.post<MessageInterface>([this.baseUrl, this.getModelName(), 'me', 'change-password'].join('/'), {
      plainPassword
    });
  }

  editUser(
    firstName: string,
    lastName: string,
    phoneNumber: string,
    type: string,
    newsletter: boolean,
    goalReminderNotification: boolean,
    frequencyActivityGoalDays: number,
    notificationHour: number
  ): Observable<User> {
    return this.http.post<User>([this.baseUrl, this.getModelName(), 'me'].join('/'), {
      firstName,
      lastName,
      phoneNumber,
      type,
      newsletter,
      goalReminderNotification,
      frequencyActivityGoalDays,
      notificationHour
    });
  }

  editUserCoachPrivacySettings(
    shareProfile: boolean,
    shareSideEffects: boolean,
    shareTreatments: boolean
  ): Observable<User> {
    return this.http.post<User>([this.baseUrl, this.getModelName(), 'me', 'coach-privacy-settings'].join('/'), {
      shareProfile,
      shareSideEffects,
      shareTreatments
    });
  }

  editUserProfile(userProfile: UserProfile): Observable<User> {
    return this.http.post<User>([this.baseUrl, this.getModelName(), 'me', 'profile'].join('/'), userProfile);
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>([this.baseUrl, this.getModelName(), 'me'].join('/'));
  }

  getSubscriptionInfo(): Observable<SubscriptionInfo> {
    return this.http.get<SubscriptionInfo>([this.baseUrl, this.getModelName(), 'me', 'subscription'].join('/'));
  }

  disableCurrentUser(): Observable<void> {
    return this.http.post<void>([this.baseUrl, this.getModelName(), 'me', 'disable'].join('/'), {});
  }

  checkIfFavoriteActivityExists(id: number): Observable<void> {
    return this.http.head<void>([this.baseUrl, this.getModelName(), 'me', 'favorite-activities', id].join('/'));
  }

  addToFavoriteActivityExists(id: number): Observable<Array<Activity>> {
    return this.http.post<Array<Activity>>([this.baseUrl, this.getModelName(), 'me', 'favorite-activities', id].join('/'), {});
  }

  removeFromFavoriteActivityExists(id: number): Observable<Array<Activity>> {
    return this.http.delete<Array<Activity>>([this.baseUrl, this.getModelName(), 'me', 'favorite-activities', id].join('/'), {});
  }

  getFavoriteActivities(): Observable<Array<Activity>> {
    return this.http.get<Array<Activity>>([this.baseUrl, this.getModelName(), 'me', 'favorite-activities'].join('/'));
  }

  getLastDailySurvey(): Observable<DailySurvey> {
    return this.http.get<DailySurvey>([this.baseUrl, this.getModelName(), 'me', 'last-daily-survey'].join('/'));
  }

  getLastDailySideEffectSurvey(): Observable<DailySurvey> {
    return this.http.get<DailySurvey>([this.baseUrl, this.getModelName(), 'me', 'last-daily-side-effect-survey'].join('/'));
  }

  getActiveUserContext(): Observable<UserContext> {
    return this.http.get<UserContext>([this.baseUrl, this.getModelName(), 'me', 'active-user-context'].join('/'));
  }

  createDailySurvey(dailySurvey: DailySurvey): Observable<DailySurvey> {
    return this.http.post<DailySurvey>([this.baseUrl, this.getModelName(), 'me', 'daily-surveys'].join('/'), dailySurvey);
  }

  getUserTreatments(): Observable<Array<Treatment>> {
    return this.http.get<Array<Treatment>>([this.baseUrl, this.getModelName(), 'me', 'treatments'].join('/'));
  }

  createTreatment(treatment: Treatment): Observable<Treatment> {
    return this.http.post<Treatment>([this.baseUrl, this.getModelName(), 'me', 'treatments'].join('/'), treatment);
  }

  skipPaymentRequest(): Observable<User> {
    return this.http.post<User>([this.baseUrl, this.getModelName(), 'me', 'skip-payment-request'].join('/'), {});
  }

  resetPaymentRequest(): Observable<User> {
    return this.http.post<User>([this.baseUrl, this.getModelName(), 'me', 'reset-payment-request'].join('/'), {});
  }

  cancelSubscription(): Observable<User> {
    return this.http.post<User>([this.baseUrl, this.getModelName(), 'me', 'cancel-subscription'].join('/'), {});
  }

  getModelName(): string {
    return 'users';
  }
}
