import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var ActivitiesRepository = /** @class */ (function (_super) {
    tslib_1.__extends(ActivitiesRepository, _super);
    function ActivitiesRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    ActivitiesRepository.prototype.getModelName = function () {
        return 'activities';
    };
    ActivitiesRepository.prototype.getSuggestions = function (id) {
        return this.http.get([this.baseUrl, this.getModelName(), id, 'suggestions'].join('/'));
    };
    ActivitiesRepository.prototype.getTutorials = function (id) {
        return this.http.get([this.baseUrl, this.getModelName(), id, 'tutorials'].join('/'));
    };
    ActivitiesRepository.prototype.logView = function (id) {
        return this.http.post([this.baseUrl, this.getModelName(), id, 'log'].join('/'), {});
    };
    ActivitiesRepository.prototype.getActivitiesByLength = function (length) {
        var filter = {
            duration: length
        };
        var params = new HttpParams({
            fromObject: filter
        });
        return this.http.get([this.baseUrl, this.getModelName()].join('/'), { params: params });
    };
    return ActivitiesRepository;
}(AbstractRepository));
export { ActivitiesRepository };
