import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity';
import { Author } from '../models/author';
import { ConstantInterface } from '../models/constants';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class AuthorsRepository extends AbstractRepository<Author, ConstantInterface> {
    constructor(protected readonly http: HttpClient) {
        super(http);
    }

    getModelName(): string {
        return 'authors';
    }

    getInLibraryAuthors(): Observable<Array<Author>> {
        return this.http.get<Array<Author>>([this.baseUrl, this.getModelName(), 'in-library'].join('/'));
    }

    getAuthorActivities(id: number): Observable<Array<Activity>> {
        return this.http.get<Array<Activity>>([this.baseUrl, this.getModelName(), id, 'activities'].join('/'));
    }
}
