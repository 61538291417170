import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StripeRepository } from '../../../repositories/stripe.repository';
import { UserRepository } from '../../../repositories/user.repository';
import { AuthenticationService } from '../authentication/authentication.service';
import { TokenService } from '../authentication/token.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  contributionForm: FormGroup;
  submitted = false;
  paymentCancelled = false;
  stripeSessionId: string;
  contribution = 10;

  constructor(private readonly formBuilder: FormBuilder,
              private readonly alertController: AlertController,
              private readonly route: ActivatedRoute,
              private readonly authenticationService: AuthenticationService,
              private readonly stripeRepository: StripeRepository,
              private readonly userRepository: UserRepository,
              private readonly router: Router,
              private readonly loadingController: LoadingController,
              private readonly modalController: ModalController,
              private readonly tokenService: TokenService
  ) {}

  async ngOnInit(): Promise<void> {
    const contribution = +this.route.snapshot.queryParamMap.get('contribution') || this.contribution;
    this.contributionForm = this.formBuilder.group({
      contribution: [contribution, [Validators.required, Validators.min(1)]]
    });

    this.contributionForm.get('contribution')
      .valueChanges
      .subscribe(newContribution => {
        this.contribution = newContribution;
        this.generateStripeSessionId(newContribution);
      });

    const status = this.route.snapshot.queryParamMap.get('status');
    if (status === 'cancel') {
      this.paymentCancelled = true;
    } else if (status === 'success') {
      await this.checkPayment();
    }
  }

  async ionViewWillEnter(): Promise<void> {
    const status = this.route.snapshot.queryParamMap.get('status');
    if (status !== 'checked') {
      await this.checkPayment();
    }
    this.generateStripeSessionId(this.contribution);
  }

  processPayment(): void {
    (window as any).Stripe(environment.stripePublicKey)
      .redirectToCheckout({
        sessionId: this.stripeSessionId
      })
      .then(result => {
        console.error(result);
      });
  }

  async skipPaymentRequest(): Promise<void> {
    this.submitted = true;
    const loading = await this.loadingController.create({
      message: 'Veuillez patienter...',
      translucent: true,
      showBackdrop: true
    });

    loading.present();

    this.userRepository.skipPaymentRequest()
      .pipe(map(() => {
        this.refreshTokenWithPaymentInfo(loading);
      }, () => {
        loading.dismiss();
        this.alertController.create({
          header: 'Impossible de vérifier votre paiement',
          message: 'Une erreur s\'est produite, veuillez réessayer.',
          buttons: [
            {
              text: 'J\'ai compris',
              handler: () => {
                this.router.navigate(['payment']);
              }
            }
          ]
        })
          .then(a => a.present())
        ;
      }))
      .subscribe();
  }

  private generateStripeSessionId(contribution: number): void {
    this.stripeSessionId = undefined;
    this.stripeRepository
      .generateStripeSessionId(this.tokenService.getToken().refreshToken, contribution)
      .subscribe(({stripeSessionId}) => {
        this.stripeSessionId = stripeSessionId;
      });
  }

  private async checkPayment(): Promise<void> {
    const loading = await this.loadingController.create({
      message: 'Veuillez patienter...',
      translucent: true,
      showBackdrop: true
    });

    loading.present();

    const sessionId = this.route.snapshot.queryParamMap.get('sessionId');

    if (sessionId) {
      this.stripeRepository.attachSubscription(sessionId)
        .pipe(map(() => {
            this.refreshTokenWithPaymentInfo(loading);
          }, () => {
            loading.dismiss();
            this.alertController.create({
              header: 'Impossible de vérifier votre paiement',
              message: 'Une erreur s\'est produite de la vérification de votre paiement. Merci de nous contacter.',
              buttons: [
                {
                  text: 'J\'ai compris',
                  handler: () => {
                    this.router.navigate(['payment']);
                  }
                }
              ]
            })
              .then(a => a.present())
            ;
        }))
        .subscribe()
      ;
    } else {
      this.refreshTokenWithPaymentInfo(loading);
    }
  }

  private refreshTokenWithPaymentInfo(loading): void {
    this.authenticationService.refreshToken(true)
      .pipe(
        map(() => {
          setTimeout(() => {
            loading.dismiss();
            // Force guard
            (window as any).location = `${window.location.href.split('?')[0]}?status=checked`;
          }, 1000);
        })
      )
      .subscribe();
  }
}
