import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as semver from 'semver';
import { i18n } from './i18n';
import { ManUpConfig } from './manup.config';
/**
 * DI InjectionToken for optional ngx-translate
 */
export var TRANSLATE_SERVICE = new InjectionToken('manup:translate');
var STORAGE_KEY = 'mycharlotte-manup';
/**
 * The types of alerts we may present
 */
export var AlertType;
(function (AlertType) {
    /**
     * A mandatory update is required
     */
    AlertType[AlertType["MANDATORY"] = 0] = "MANDATORY";
    /**
     * An optional update is available
     */
    AlertType[AlertType["OPTIONAL"] = 1] = "OPTIONAL";
    /**
     * The app is disabled
     */
    AlertType[AlertType["MAINTENANCE"] = 2] = "MAINTENANCE";
    /**
     * Nothing to see here
     */
    AlertType[AlertType["NOP"] = 3] = "NOP";
})(AlertType || (AlertType = {}));
var ManUpService = /** @class */ (function () {
    function ManUpService(config, http, alert, platform, iab, appVersion, translate, storage) {
        this.config = config;
        this.http = http;
        this.alert = alert;
        this.platform = platform;
        this.iab = iab;
        this.appVersion = appVersion;
        this.translate = translate;
        this.storage = storage;
        /**
         * True if there is an alert already displayed. Used to prevent multiple alerts
         * being presented on top of one another
         */
        this.inProgress = false;
    }
    /**
     * Loads the translations into the translation service.
     *
     * * Loads the language requested, if available
     * * Loads the default lang, if we have it
     * * Loads english into the default lang as a last resort
     */
    ManUpService.prototype.loadTranslations = function () {
        if (i18n[this.translate.currentLang]) {
            this.translate.setTranslation(this.translate.currentLang, i18n[this.translate.currentLang].translations, true);
        }
        else if (i18n[this.translate.defaultLang]) {
            this.translate.setTranslation(this.translate.defaultLang, i18n[this.translate.defaultLang].translations, true);
        }
        else {
            this.translate.setTranslation(this.translate.defaultLang, i18n.fr.translations, true);
        }
    };
    /**
     * Begins the manup check process.
     *
     * @Returns a promise that resolves if the app is able to continue.
     */
    ManUpService.prototype.validate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    if (!this.inProgress) {
                        this.inProgress = true;
                        this.currentPromise = new Promise(function (resolve, reject) {
                            _this.platform.ready().then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var metadata, platformData, result, _a, e_1;
                                return tslib_1.__generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, this.metadata()];
                                        case 1:
                                            metadata = _b.sent();
                                            // Be generous, if we couldn't get the ManUp data let the app run
                                            if (!metadata) {
                                                return [2 /*return*/, resolve()];
                                            }
                                            _b.label = 2;
                                        case 2:
                                            _b.trys.push([2, 9, , 10]);
                                            return [4 /*yield*/, this.getPlatformData(metadata)];
                                        case 3:
                                            platformData = _b.sent();
                                            return [4 /*yield*/, this.evaluate(platformData)];
                                        case 4:
                                            result = _b.sent();
                                            _a = result;
                                            switch (_a) {
                                                case AlertType.NOP: return [3 /*break*/, 5];
                                            }
                                            return [3 /*break*/, 6];
                                        case 5:
                                            resolve();
                                            return [3 /*break*/, 8];
                                        case 6: return [4 /*yield*/, this.presentAlert(result, platformData)];
                                        case 7:
                                            _b.sent();
                                            resolve();
                                            _b.label = 8;
                                        case 8: return [3 /*break*/, 10];
                                        case 9:
                                            e_1 = _b.sent();
                                            return [2 /*return*/, resolve()];
                                        case 10: return [2 /*return*/];
                                    }
                                });
                            }); });
                        });
                    }
                    return [2 /*return*/, this.currentPromise];
                }
                catch (err) {
                    return [2 /*return*/, Promise.resolve()];
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Evaluates what kind of update is required, if any.
     *
     * Returns a promise that resolves with an alert type.
     */
    ManUpService.prototype.evaluate = function (metadata) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var version;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!metadata.enabled) {
                            return [2 /*return*/, Promise.resolve(AlertType.MAINTENANCE)];
                        }
                        return [4 /*yield*/, this.appVersion.getVersionNumber()];
                    case 1:
                        version = _a.sent();
                        if (semver.lt(version, metadata.minimum)) {
                            return [2 /*return*/, AlertType.MANDATORY];
                        }
                        else if (semver.lt(version, metadata.latest)) {
                            return [2 /*return*/, AlertType.OPTIONAL];
                        }
                        return [2 /*return*/, AlertType.NOP];
                }
            });
        });
    };
    /**
     * Fetches the remote metadata and returns an observable with the json
     */
    ManUpService.prototype.metadata = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http
                                .get(this.config.url)
                                .toPromise()];
                    case 1:
                        response = _a.sent();
                        if (this.storage) {
                            this.saveMetadata(response)
                                // tslint:disable-next-line:no-empty
                                .catch(function () { });
                        }
                        return [2 /*return*/, response];
                    case 2:
                        err_1 = _a.sent();
                        return [2 /*return*/, this.metadataFromStorage()];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Gets the version metadata from storage, if available.
     *
     * @throws An error if the service was instantiated without a Storage component.
     * @returns {Promise<any>} That resolves with the metadata
     *
     * @memberOf ManUpService
     */
    ManUpService.prototype.metadataFromStorage = function () {
        if (this.storage) {
            return this.storage.get(STORAGE_KEY + ".manup")
                .then(function (data) { return JSON.parse(data); });
        }
        throw new Error('Storage not configured');
    };
    ManUpService.prototype.saveMetadata = function (metadata) {
        if (this.storage) {
            return this.storage.set(STORAGE_KEY + ".manup", JSON.stringify(metadata));
        }
        else {
            throw new Error('Storage not configured');
        }
    };
    /**
     * Returns the branch of the metadata relevant to this platform
     */
    ManUpService.prototype.getPlatformData = function (metadata) {
        if (!metadata) {
            throw new Error('metadata does not exist');
        }
        if (this.platform.is('ios')) {
            return metadata.ios;
        }
        if (this.platform.is('android')) {
            return metadata.android;
        }
        throw new Error('Unknown platform');
    };
    /**
     * Presents an update alert.
     *
     * @param type The type of alert to show
     * @param platformData The metadata for the platform
     *
     * @returns A promise that resolves when this whole thing is over.
     */
    ManUpService.prototype.presentAlert = function (type, platformData) {
        // load the translations unless we've been told not to
        if (this.translate && !this.config.externalTranslations) {
            this.loadTranslations();
        }
        switch (type) {
            case AlertType.MANDATORY:
                return this.presentMandatoryUpdate(platformData);
            case AlertType.OPTIONAL:
                return this.presentOptionalUpdate(platformData);
            case AlertType.MAINTENANCE:
                return this.presentMaintenanceMode();
            default:
        }
    };
    /**
     * Displays a maintenance mode alert.
     *
     * @returns a promise that will never resolve, because the app should not continue
     */
    ManUpService.prototype.presentMaintenanceMode = function () {
        var _this = this;
        return this.appVersion.getAppName()
            .then(function (name) {
            return new Promise(function (resolve, reject) {
                _this.alert.create({
                    backdropDismiss: false,
                    header: _this.translate
                        ? _this.translate.instant('manup.maintenance.title', { app: name })
                        : name + " indisponible",
                    // tslint:disable-next-line:max-line-length
                    message: _this.translate
                        ? _this.translate.instant('manup.maintenance.text', { app: name })
                        : name + " est pour l'instant indisponible, merci de revenir dans quelques instants.",
                    buttons: ['OK']
                })
                    .then(function (t) { return t.present(); });
            });
        });
    };
    /**
     * Displays a mandatory update alert.
     *
     * @returns a promise that will never resolve, because the app should not continue
     */
    ManUpService.prototype.presentMandatoryUpdate = function (platformData) {
        var _this = this;
        return this.appVersion.getAppName()
            .then(function (name) {
            return new Promise(function (resolve, reject) {
                _this.alert.create({
                    backdropDismiss: false,
                    header: _this.translate
                        ? _this.translate.instant('manup.mandatory.title', { app: name })
                        : 'Une mise à jour est requise',
                    message: _this.translate
                        ? _this.translate.instant('manup.mandatory.text', { app: name })
                        : "Une mise \u00E0 jour vers " + name + " est requise pour continuer.",
                    buttons: [
                        {
                            text: _this.translate ? _this.translate.instant('manup.buttons.update') : 'Mettre à jour',
                            handler: function () {
                                _this.iab.create(platformData.url, '_system');
                                return false;
                            }
                        }
                    ]
                })
                    .then(function (t) { return t.present(); });
            });
        });
    };
    /**
     * Displays an optional update alert.
     *
     * @returns a promise that will resolves if the user selects 'not now'
     */
    ManUpService.prototype.presentOptionalUpdate = function (platformData) {
        var _this = this;
        return this.appVersion.getAppName()
            .then(function (name) {
            return new Promise(function (resolve, reject) {
                _this.alert.create({
                    backdropDismiss: false,
                    header: _this.translate
                        ? _this.translate.instant('manup.optional.title', { app: name })
                        : 'Mise à jour disponible',
                    message: _this.translate
                        ? _this.translate.instant('manup.optional.text', { app: name })
                        : "Une mise \u00E0 jour vers " + name + " est disponible. Voulez-vous mettre \u00E0 jour ?",
                    buttons: [
                        {
                            text: _this.translate ? _this.translate.instant('manup.buttons.later') : 'Plus tard',
                            handler: function () {
                                resolve();
                            }
                        },
                        {
                            text: _this.translate ? _this.translate.instant('manup.buttons.update') : 'Mettre à jour',
                            handler: function () {
                                _this.iab.create(platformData.url, '_system');
                                return false;
                            }
                        }
                    ]
                })
                    .then(function (t) { return t.present(); });
            });
        });
    };
    return ManUpService;
}());
export { ManUpService };
