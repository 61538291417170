import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Activity } from '../models/activity';
import { AuthorsRepository } from '../repositories/authors.repository';

@Injectable()
export class AuthorActivitiesResolver implements Resolve<Array<Activity>> {
  constructor(
    private readonly router: Router,
    private readonly toastController: ToastController,
    private readonly authorsRepository: AuthorsRepository
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Array<Activity>> {
    const id = +route.paramMap.get('authorId');

    return this.authorsRepository.getAuthorActivities(id)
      .pipe(
        catchError(() => this.handleError())
      );
  }

  private handleError(): Observable<Array<Activity>> {
    this.toastController.create({
      message: 'Impossible de récupérer les activités de cet auteur',
      duration: 2000
    })
      .then(t => t.present());
    this.router.navigate(['..']);

    return of();
  }
}
