import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { UserRepository } from '../../../repositories/user.repository';
import { setFormErrors } from '../form-error/form.mapper';
import { CGUPage } from './cgu/cgu.page';
import { ConfidentialityAgreementComponent } from './confidentiality-agreement/confidentiality-agreement.component';
var repeatPasswordValidator = function (group) {
    var password = group.get('plainPassword').value;
    var passwordConfirmation = group.get('confirmPassword').value;
    return password === passwordConfirmation ? undefined : { passwordsNotEqual: true };
};
var ɵ0 = repeatPasswordValidator;
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(formBuilder, route, userRepository, modalController, alertController, router) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.userRepository = userRepository;
        this.modalController = modalController;
        this.alertController = alertController;
        this.router = router;
        this.formError = false;
        this.submitted = false;
        this.loading = true;
    }
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (data) {
            _this.constants = data.constants;
            _this.registerForm = _this.formBuilder.group({
                firstName: ['', Validators.required],
                lastName: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                plainPassword: ['', [Validators.required, Validators.minLength(4)]],
                confirmPassword: ['', Validators.required],
                type: ['', Validators.required],
                healthInstitution: [''],
                otherHealthInstitution: [''],
                newsletter: [false],
                renunciation: [false, Validators.requiredTrue]
            }, {
                validator: repeatPasswordValidator
            });
            _this.loading = false;
        });
    };
    RegisterComponent.prototype.openCGU = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: CGUPage,
                            componentProps: {
                                contentCGU: this.constants.cgu
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterComponent.prototype.openConfidentialityAgreement = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: ConfidentialityAgreementComponent,
                            componentProps: {
                                contentCA: this.constants.confidentialityAgreement
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    RegisterComponent.prototype.register = function () {
        var _this = this;
        if (this.registerForm.invalid || this.submitted) {
            return;
        }
        this.userRepository.create({
            // tslint:disable-next-line:newline-per-chained-call
            username: this.registerForm.get('email').value,
            email: this.registerForm.get('email').value,
            // tslint:disable-next-line:newline-per-chained-call
            firstName: this.registerForm.get('firstName').value,
            // tslint:disable-next-line:newline-per-chained-call
            lastName: this.registerForm.get('lastName').value,
            // tslint:disable-next-line:newline-per-chained-call
            newsletter: this.registerForm.get('newsletter').value,
            // tslint:disable-next-line:newline-per-chained-call
            plainPassword: this.registerForm.get('plainPassword').value,
            type: this.registerForm.get('type').value,
            healthInstitution: this.registerForm.get('healthInstitution').value,
            otherHealthInstitution: this.registerForm.get('otherHealthInstitution').value
        })
            .subscribe(function () {
            _this.formError = false;
            _this.submitted = false;
            _this.alertController.create({
                header: 'Validez votre email',
                // tslint:disable-next-line:max-line-length
                message: 'Vous allez recevoir un email dans les prochaines secondes, merci de cliquer sur le lien dans l\'email pour valider votre inscription.',
                buttons: ['OK']
            })
                .then(function (t) { return t.present(); });
            _this.router.navigate(['login']);
        }, function (err) {
            var status = err.status;
            if (status === 422) {
                setFormErrors(_this.registerForm, err.error.form);
            }
            _this.formError = true;
            _this.submitted = false;
            return err;
        });
    };
    return RegisterComponent;
}());
export { RegisterComponent };
export { ɵ0 };
