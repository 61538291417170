import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';

Sentry.init({
  dsn: environment.sentryDsn,
  release: environment.sentryDsn,
  ignoreErrors: [
    'Non-Error exception captured'
  ]
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  // tslint:disable-next-line:typedef
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}
