import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { User } from '../../../models/user';
import { UserRepository } from '../../../repositories/user.repository';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-confirm-registration',
  template: ''
})
export class ConfirmRegistrationComponent implements OnInit {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly toastController: ToastController,
    private readonly userRepository: UserRepository
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (!token) {
      this.toastController.create({
        message: 'Aucun jeton : veuillez cliquer sur le lien présent dans le mail.',
        duration: 5000
      })
        .then(t => t.present());
    } else {
      this.userRepository.enableUser(token)
        .subscribe((user: User) => {
          this.toastController.create({
            message: 'Votre compte est désormais activé ! Vous pouvez dès maintenant vous connecter.',
            duration: 5000
          })
            .then(t => t.present());
          this.router.navigate(['login'], { queryParams: { email: user.email } });
        }, (error: HttpErrorResponse) => {
          this.toastController.create({
            message: error.error.message,
            duration: 5000
          })
            .then(t => t.present());
        })
      ;
    }
    this.router.navigate(['login']);
  }
}
