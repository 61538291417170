<ion-content scrollY="true">
  <div class="register-wrapper" fxLayoutAlign="center center">
    <div fxLayoutAlign.gt-xs="center center" fxFlexFill>
      <ion-card class="register">
      <ion-toolbar color="primary">
          <ion-buttons slot="start">
              <ion-button
                  [routerLink]="['/login']"
                  type="button"
              >
                  <ion-icon name="arrow-back"></ion-icon>
              </ion-button>
          </ion-buttons>
          <ion-title>Inscription chez myCharlotte !</ion-title>
      </ion-toolbar>
      <ion-card-content class="register-content">
        <form fxLayout="column" fxLayoutGap="10px" [formGroup]="registerForm" (submit)="register()" (keyup.enter)="register()" novalidate *ngIf="!loading">
          <ion-item *ngIf="formError" color="danger">
            <ion-text>
              Erreur lors de l'inscription
            </ion-text>
          </ion-item>

          <div>
            <ion-item>
              <ion-label position="stacked">Prénom <ion-text color="danger">*</ion-text></ion-label>
              <ion-input
                type="text"
                required
                formControlName="firstName"
                placeholder="Prénom"
                clearInput
              ></ion-input>
            </ion-item>
            <app-form-error
              [field]="registerForm.get('firstName')"
              [messages]="{'required': 'Veuillez saisir le prénom'}"
            ></app-form-error>
          </div>

          <div>
            <ion-item>
              <ion-label position="stacked">Nom <ion-text color="danger">*</ion-text></ion-label>
              <ion-input
                type="text"
                required
                formControlName="lastName"
                placeholder="Nom"
                clearInput
              ></ion-input>
            </ion-item>
            <app-form-error
              [field]="registerForm.get('lastName')"
              [messages]="{'required': 'Veuillez saisir le nom'}"
            ></app-form-error>
          </div>

          <div>
            <ion-item>
              <ion-label position="stacked">Email <ion-text color="danger">*</ion-text></ion-label>
              <ion-input
                type="email"
                required
                autocorrect="none"
                autocapitalize="none"
                formControlName="email"
                placeholder="charlotte@mycharlotte.fr"
                clearInput
              ></ion-input>
            </ion-item>
            <app-form-error
                  [field]="registerForm.get('email')"
                  [messages]="{
                    'required': 'Veuillez saisir l\'email',
                    'email': 'Veuillez saisir un email valide'
                  }"
            ></app-form-error>
          </div>

          <div>
            <ion-item>
              <ion-label position="stacked">Mot de passe <ion-text color="danger">*</ion-text></ion-label>
              <ion-input
                  type="password"
                  required
                  autocorrect="none"
                  autocapitalize="none"
                  formControlName="plainPassword"
                  placeholder="Mot de passe"
                  clearInput
                  #toggleInput
              ></ion-input>
              <ion-button type="button" color="light" class="my-auto" slot="end" passwordToggle [targetInput]="toggleInput">
                <ion-icon name="eye"></ion-icon>
              </ion-button>
            </ion-item>
            <app-form-error
              [field]="registerForm.get('plainPassword')"
              [messages]="{
                'required': 'Veuillez confirmer le mot de passe',
                'minlength': 'Veuillez saisir 4 caractères au minimum'
              }"
            ></app-form-error>
          </div>

          <div>
            <ion-item>
              <ion-label position="stacked">Confirmez votre mot de passe <ion-text color="danger">*</ion-text></ion-label>
              <ion-input
                  type="password"
                  required
                  autocorrect="none"
                  autocapitalize="none"
                  formControlName="confirmPassword"
                  placeholder="Confirmez votre mot de passe"
                  clearInput
                  #toggleInputConfirmation
              ></ion-input>
              <ion-button type="button" color="light" class="my-auto" slot="end" passwordToggle [targetInput]="toggleInputConfirmation">
                <ion-icon name="eye"></ion-icon>
              </ion-button>
            </ion-item>
            <app-form-error
                  [field]="registerForm.get('confirmPassword')"
                  [messages]="{'required': 'Veuillez saisir la confirmation'}"
            ></app-form-error>
            <ion-text color="danger">
              <span *ngIf="registerForm.hasError('passwordsNotEqual') && registerForm.get('confirmPassword').touched">Les mots de passe doivent être identiques</span>
            </ion-text>
          </div>

          <div>
            <ion-list-header>
              <ion-label text-wrap>myCharlotte est conçue pour les femmes atteintes d'un cancer du sein</ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label text-wrap position="stacked">Vous êtes ? <ion-text color="danger">*</ion-text></ion-label>
              <ion-select
                formControlName="type"
                placeholder="Vous êtes ?"
                cancelText="Annuler"
                interface="alert"
              >
                <ion-select-option *ngFor="let type of constants.types" [value]="type.value">{{ type.label }}</ion-select-option>
              </ion-select>
            </ion-item>
            <app-form-error
                [field]="registerForm.get('type')"
                [messages]="{'required': 'Veuillez préciser votre type de profil'}"
            ></app-form-error>
          </div>

          <div *ngIf="registerForm.get('type').value === 'beingTreated' || registerForm.get('type').value === 'postTreatment'">
            <ion-item>
              <ion-label text-wrap position="stacked">Vous vous inscrivez via ?</ion-label>
              <ion-select
                formControlName="healthInstitution"
                placeholder="Vous vous inscrivez via ?"
                cancelText="Annuler"
                interface="alert"
              >
                <ion-select-option *ngFor="let healthInstitution of constants.healthInstitutions" [value]="healthInstitution.id">{{ healthInstitution.name }}</ion-select-option>
                <ion-select-option value>De manière autonome</ion-select-option>
              </ion-select>
            </ion-item>
            <app-form-error
                [field]="registerForm.get('healthInstitution')"
            ></app-form-error>
          </div>

          <div *ngIf="(registerForm.get('type').value === 'beingTreated' || registerForm.get('type').value === 'postTreatment') && !registerForm.get('healthInstitution').value">
            <ion-item>
              <ion-label text-wrap position="stacked">Dans quel centre de soin êtes-vous traitée ?</ion-label>
              <ion-input
                  type="text"
                  formControlName="otherHealthInstitution"
                  placeholder="Votre centre de soin"
                  clearInput
              ></ion-input>
            </ion-item>
            <app-form-error
                [field]="registerForm.get('otherHealthInstitution')"
            ></app-form-error>
          </div>

          <div class="register-checkbox">
            <ion-item>
              <ion-label text-wrap>Je souhaite recevoir des informations sur l'utilisation de l'application et sur le développement de myCharlotte.</ion-label>
              <ion-checkbox slot="start" formControlName="newsletter"></ion-checkbox>
            </ion-item>
            <app-form-error
                [field]="registerForm.get('newsletter')"
            ></app-form-error>
          </div>

          <div class="register-checkbox">
            <ion-item>
              <ion-label text-wrap>En m'inscrivant, je déclare avoir pris connaissance et accepté sans réserve les conditions générales d'utilisation et la politique de données personnelles.
                <ion-text color="danger">*</ion-text></ion-label>
              <ion-checkbox slot="start" formControlName="renunciation"></ion-checkbox>
            </ion-item>
            <app-form-error
                [messages]="{'required': 'Vous devez accepter les conditions générales d\'utilisation et la politique de données personnelles'}"
                [field]="registerForm.get('renunciation')"
            ></app-form-error>
          </div>

          <ion-text class="register-acceptation">
            Cliquez pour prendre connaissance des <a tappable (click)="openCGU()">conditions générales d'utilisation</a> et de <a tappable (click)="openConfidentialityAgreement()">la politique de données personnelles</a>.
          </ion-text>

          <div fxLayout="row" fxLayoutAlign="center center" class="register-button">
            <ion-button
              color="primary"
              type="submit"
              [disabled]="registerForm.invalid || submitted"
            >
                Je rejoins myCharlotte
            </ion-button>
          </div>
        </form>

        <hr class="register-form-divider" />
        <ion-label>
          <h2>Vous êtes déjà inscrite?</h2>
        </ion-label>
        <div fxLayout="row" fxLayoutAlign="center center" class="register-button">
          <ion-button
              fill="outline"
              type="button"
              color="primary"
              [routerLink]="['/login']"
          >
              S'identifier
          </ion-button>
        </div>
      </ion-card-content>
      </ion-card>
    </div>
  </div>
</ion-content>
