import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserContextStep } from '../models/user-context-step';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class UserContextStepRepository extends AbstractRepository<UserContextStep> {
  constructor(protected readonly http: HttpClient) {
    super(http);
  }

  ignoreUserContextStep(id: number): Observable<UserContextStep> {
    return this.http.post<UserContextStep>([this.baseUrl, this.getModelName(), id, 'ignore'].join('/'), {});
  }

  validateUserContextStep(id: number): Observable<UserContextStep> {
    return this.http.post<UserContextStep>([this.baseUrl, this.getModelName(), id, 'validate'].join('/'), {});
  }

  getModelName(): string {
    return 'user-context-steps';
  }
}
