<ion-content scrollY="true">
  <div class="request-reset-password-wrapper" fxLayoutAlign="center center">
    <div fxLayoutAlign.gt-xs="center center" fxFlexFill>
      <ion-card class="request-reset-password">
      <ion-toolbar color="primary">
          <ion-buttons slot="start">
              <ion-button
                  [routerLink]="['/login']"
                  type="button"
              >
                  <ion-icon name="arrow-back"></ion-icon>
              </ion-button>
          </ion-buttons>
          <ion-title>Réinitialiser mon mot de passe (1/2)</ion-title>
      </ion-toolbar>
      <ion-card-content class="request-reset-password-content">
        <img class="request-reset-password-icon" src="assets/images/logo.png" alt="myCharlotte"/>
        <form fxLayout="column" fxLayoutGap="10px" [formGroup]="requestResetPasswordForm" (submit)="requestResetPassword()" (keyup.enter)="requestResetPassword()" novalidate>
          <ion-text>Pour demander la réinitialisation de votre mot de passe, veuillez saisir votre email.</ion-text>

          <div>
            <ion-item>
              <ion-label position="stacked">Email <ion-text color="danger">*</ion-text></ion-label>
              <ion-input
                type="email"
                required
                autocorrect="none"
                autocapitalize="none"
                formControlName="email"
                placeholder="charlotte@mycharlotte.fr"
                clearInput
              ></ion-input>
            </ion-item>
            <app-form-error
                  [field]="requestResetPasswordForm.get('email')"
                  [messages]="{
                    'required': 'Veuillez saisir l\'email',
                    'email': 'Veuillez saisir un email valide'
                  }"
            ></app-form-error>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" class="request-reset-password-button">
            <ion-button
              color="primary"
              type="submit"
              [disabled]="requestResetPasswordForm.invalid || submitted"
            >
                Réinitialiser mon mot de passe
            </ion-button>
          </div>
        </form>
      </ion-card-content>
      </ion-card>
    </div>
  </div>
</ion-content>
