import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  formError = false;
  loginForm: FormGroup;
  submitted = false;

  constructor(private readonly formBuilder: FormBuilder,
              private readonly route: ActivatedRoute,
              private readonly authenticationService: AuthenticationService,
              private readonly router: Router) {
  }

  ngOnInit(): void {
    const email = this.route.snapshot.queryParamMap.get('email');

    this.loginForm = this.formBuilder.group({
      username: [email, Validators.required],
      password: ['', Validators.required]
    });
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.submitted = true;
    this.authenticationService
      .login((this.loginForm.get('username').value || '').trim(), this.loginForm.get('password').value)
      .subscribe(() => {
        this.formError = false;
        this.submitted = false;
        this.router.navigate(['menu', 'programme']);
      }, () => {
        this.loginForm.get('password')
          .reset();
        this.formError = true;
        this.submitted = false;
      });
  }

  getUsernameErrorMessage(): string {
    return this.loginForm.get('username')
      .hasError('required') ? 'Veuillez saisir votre email' : '';
  }

  getPasswordErrorMessage(): string {
    return this.loginForm.get('password')
      .hasError('required') ? 'Veuillez saisir votre mot de passe' : '';
  }

  ngAfterViewInit(): void {
    const email = this.route.snapshot.queryParamMap.get('email');
    if (email) {
      this.loginForm.get('username')
        .setValue(email);
    }
  }
}
