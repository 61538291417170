import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Benefit } from '../models/benefit';
import { BenefitsRepository } from '../repositories/benefits.repository';

@Injectable()
export class ActivityBenefitResolver implements Resolve<Benefit> {
  constructor(
    private readonly router: Router,
    private readonly toastController: ToastController,
    private readonly activityBenefitsRepository: BenefitsRepository
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<Benefit> {
    const code = route.paramMap.get('benefitCode');

    return this.activityBenefitsRepository.findByCode(code)
      .pipe(
        catchError(() => this.handleError())
      );
  }

  private handleError(): Observable<Benefit> {
    this.toastController.create({
      message: 'Impossible de récupérer les catégories d\'activités',
      duration: 2000
    })
      .then(t => t.present());
    this.router.navigate(['..']);

    return of();
  }
}
