import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AuthenticationService } from '../authentication/authentication.service';
var LogoutComponent = /** @class */ (function () {
    function LogoutComponent(authService, router, route, toastController) {
        var _this = this;
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.toastController = toastController;
        route.params.subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authService.logout()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.toastController.create({
                                message: 'Vous êtes désormais déconnectée',
                                duration: 2000
                            })];
                    case 2:
                        toast = _a.sent();
                        toast.present();
                        return [4 /*yield*/, this.router.navigate(['/login'])];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    }
    return LogoutComponent;
}());
export { LogoutComponent };
