import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TreatmentsConstants } from '../models/constants';
import { TreatmentsRepository } from '../repositories/treatments.repository';

@Injectable()
export class TreatmentsConstantsResolver implements Resolve<TreatmentsConstants> {
  constructor(
    private readonly treatmentsRepository: TreatmentsRepository,
    private readonly router: Router,
    private readonly toastController: ToastController
  ) {
    this.handleError = this.handleError.bind(this);
  }

  resolve(route: ActivatedRouteSnapshot,
          state: RouterStateSnapshot): Observable<TreatmentsConstants> {
    return this.treatmentsRepository.constants()
      .pipe(
        catchError(() => this.handleError())
    );
  }

  private handleError(): Observable<TreatmentsConstants> {
    this.toastController.create({
      message: 'Erreur de récupération de la page',
      duration: 2000
    })
      .then(t => t.present());

    return of();
  }
}
