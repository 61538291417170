import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamGroup } from '../models/teamGroup';
import { AbstractRepository } from './abstract.repository';

@Injectable()
export class TeamGroupRepository extends AbstractRepository<Array<TeamGroup>> {
    constructor(protected readonly http: HttpClient) {
        super(http);
    }

    getModelName(): string {
        return 'team-groups';
    }

    getTeamGroup(): Observable<Array<TeamGroup>> {
         return this.http.get<Array<TeamGroup>>([this.baseUrl, this.getModelName()].join('/'));
    }
}
