import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AbstractRepository } from './abstract.repository';
var UserRepository = /** @class */ (function (_super) {
    tslib_1.__extends(UserRepository, _super);
    function UserRepository(http) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        return _this;
    }
    UserRepository.prototype.login = function (username, password) {
        return this.http.post([this.baseUrl, 'login_check'].join('/'), {
            username: username,
            password: password
        });
    };
    UserRepository.prototype.refreshToken = function (refreshToken) {
        return this.http.post([this.baseUrl, 'token', 'refresh'].join('/'), {
            refresh_token: refreshToken
        });
    };
    UserRepository.prototype.requestResetPassword = function (username) {
        return this.http.post([this.baseUrl, this.getModelName(), 'request-reset-password'].join('/'), {
            username: username
        });
    };
    UserRepository.prototype.resetPassword = function (token, plainPassword) {
        return this.http.post([this.baseUrl, this.getModelName(), 'reset-password', token].join('/'), {
            plainPassword: plainPassword
        });
    };
    UserRepository.prototype.enableUser = function (token) {
        return this.http.post([this.baseUrl, this.getModelName(), 'enable-user', token].join('/'), {});
    };
    UserRepository.prototype.changePassword = function (plainPassword) {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'change-password'].join('/'), {
            plainPassword: plainPassword
        });
    };
    UserRepository.prototype.editUser = function (firstName, lastName, phoneNumber, type, newsletter, goalReminderNotification, frequencyActivityGoalDays, notificationHour) {
        return this.http.post([this.baseUrl, this.getModelName(), 'me'].join('/'), {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            type: type,
            newsletter: newsletter,
            goalReminderNotification: goalReminderNotification,
            frequencyActivityGoalDays: frequencyActivityGoalDays,
            notificationHour: notificationHour
        });
    };
    UserRepository.prototype.editUserCoachPrivacySettings = function (shareProfile, shareSideEffects, shareTreatments) {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'coach-privacy-settings'].join('/'), {
            shareProfile: shareProfile,
            shareSideEffects: shareSideEffects,
            shareTreatments: shareTreatments
        });
    };
    UserRepository.prototype.editUserProfile = function (userProfile) {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'profile'].join('/'), userProfile);
    };
    UserRepository.prototype.getCurrentUser = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'me'].join('/'));
    };
    UserRepository.prototype.getSubscriptionInfo = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'me', 'subscription'].join('/'));
    };
    UserRepository.prototype.disableCurrentUser = function () {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'disable'].join('/'), {});
    };
    UserRepository.prototype.checkIfFavoriteActivityExists = function (id) {
        return this.http.head([this.baseUrl, this.getModelName(), 'me', 'favorite-activities', id].join('/'));
    };
    UserRepository.prototype.addToFavoriteActivityExists = function (id) {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'favorite-activities', id].join('/'), {});
    };
    UserRepository.prototype.removeFromFavoriteActivityExists = function (id) {
        return this.http.delete([this.baseUrl, this.getModelName(), 'me', 'favorite-activities', id].join('/'), {});
    };
    UserRepository.prototype.getFavoriteActivities = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'me', 'favorite-activities'].join('/'));
    };
    UserRepository.prototype.getLastDailySurvey = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'me', 'last-daily-survey'].join('/'));
    };
    UserRepository.prototype.getLastDailySideEffectSurvey = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'me', 'last-daily-side-effect-survey'].join('/'));
    };
    UserRepository.prototype.getActiveUserContext = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'me', 'active-user-context'].join('/'));
    };
    UserRepository.prototype.createDailySurvey = function (dailySurvey) {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'daily-surveys'].join('/'), dailySurvey);
    };
    UserRepository.prototype.getUserTreatments = function () {
        return this.http.get([this.baseUrl, this.getModelName(), 'me', 'treatments'].join('/'));
    };
    UserRepository.prototype.createTreatment = function (treatment) {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'treatments'].join('/'), treatment);
    };
    UserRepository.prototype.skipPaymentRequest = function () {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'skip-payment-request'].join('/'), {});
    };
    UserRepository.prototype.resetPaymentRequest = function () {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'reset-payment-request'].join('/'), {});
    };
    UserRepository.prototype.cancelSubscription = function () {
        return this.http.post([this.baseUrl, this.getModelName(), 'me', 'cancel-subscription'].join('/'), {});
    };
    UserRepository.prototype.getModelName = function () {
        return 'users';
    };
    return UserRepository;
}(AbstractRepository));
export { UserRepository };
