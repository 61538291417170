import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-errors.component.html'
})
export class FormErrorComponent implements OnInit {
  @Input() field: AbstractControl;
  @Input() messages: {[errorKey: string]: string};
  errorMessages: {[errorKey: string]: string};

  private readonly defaultMessages = {
    required: 'Ce champ est requis.',
    forceYes: 'Vous devez répondre "Oui" pour continuer'
  };

  ngOnInit(): void {
    this.errorMessages = {...this.defaultMessages, ...this.messages};
  }
}
